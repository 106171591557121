import axios, { AxiosResponse, AxiosError } from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const downloadDetallesCapacitacion = async (token: string, nomina: number): Promise<any> => {
    try {
        const response: AxiosResponse = await axios.get(`${apiUrl}/api/capacitacion/download/colaborador/${nomina}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status === 200) {
            // Devuelve directamente los datos de la respuesta
            return response.data;
        } else {
            // Lanza un error si el estado de la respuesta no es 200
            throw new Error(`Error, ${response.status}`);
        }
    } catch (error) {
        // Manejo de errores tanto para errores de Axios como para otros errores
        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError;
            if (axiosError.response) {
                // Imprime en consola los errores provenientes del API
                console.error('Error del API:', axiosError.response.status, axiosError.response.data);
            } else {
                // Imprime en consola los errores de red
                console.error('Error de red:', axiosError.message);
            }
        } else {
            // Imprime en consola otros tipos de errores que puedan ocurrir
            console.error('Error desconocido:', error);
        }
        // Propaga el error para ser manejado por el llamador
        throw error;
    }
};