import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { getLiderDirecciones } from '../../services/getLiderDirecciones';
import { actualizarFormador } from '../../services/actualizarFormador';
import Button from '@mui/material/Button';
import '../colabsFilterModal/colabsFilterModal.css';
import Quit from '../../assets/quit.png';
import { Snackbar } from '@mui/material';
import { Formador } from '../../types/types';

interface UpdateFormadorModalProps {
  isOpen: boolean;
  onClose: () => void;
  formador: Formador | null;
  updateFormador: (subdireccion: string) => void;
  setFormadorActualizado: (updated: boolean) => void; // Añade esto
}

interface Direccion {
  subdireccion: string;
}

function UpdateFormadorModal({
  isOpen,
  onClose,
  formador,
  updateFormador,
  setFormadorActualizado 
}: UpdateFormadorModalProps) {
  const [subdirecciones, setSubdirecciones] = useState<string[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [selectedSubdireccion, setSelectedSubdireccion] = useState<string | null>(null);

  useEffect(() => {
    const token = sessionStorage.getItem('token');

    const loadSubdirecciones = async () => {
      if (token) {
        try {
          const response = await getLiderDirecciones(token);
          const subdirsSet = new Set(
            response.direcciones.map((dir: { subdireccion: string }) => dir.subdireccion)
          );
          const subdirsArray: string[] = Array.from(subdirsSet) as string[];
          setSubdirecciones(subdirsArray);
        } catch (error) {
          console.error('Error al cargar subdirecciones', error);
        }
      } else {
        console.error('Token no disponible');
      }
    };

    loadSubdirecciones();
  }, []);

  const handleUpdate = async () => {
    const token = sessionStorage.getItem('token');
    if (selectedSubdireccion && formador && formador.nomina && token) {
      try {
        await actualizarFormador(token, formador.nomina, selectedSubdireccion);
        console.log('Formador actualizado exitosamente');
        setFormadorActualizado(true); // Notificar al componente padre
        setSnackbarOpen(true); // Abrir el Snackbar
        setTimeout(onClose, 2000); // Cerrar el modal después de un delay para que el usuario vea el mensaje
      } catch (error) {
        console.error('Error al actualizar el formador:', error);
      }
    } else {
      console.error('Datos faltantes para la actualización');
    }
  };

  const handleClose = () => onClose();

  if (!isOpen) return null;

  return (
    <div className="colabs-filter-modal">
      <div className="colabs-filter-container-trackAnual">
        <div className="colabs-filter-header-modal">
          <p>Actualizar Subdirección</p>
          <img src={Quit} alt="Cerrar" onClick={handleClose} />
        </div>
        <Autocomplete
          value={selectedSubdireccion}
          onChange={(event, newValue) => {
            setSelectedSubdireccion(newValue);
          }}
          options={subdirecciones}
          getOptionLabel={(option) => option || option} // Asegúrate de adaptar esto según la estructura de tus datos
          renderInput={(params) => <TextField {...params} label="Subdirección" />}
          freeSolo
          renderOption={(params, options) => (
            <li {...params} className="custom-autocomplete-option">
              {options || options}
            </li>
          )}
        />
        <div className="filter-buttons-modal">
          <Button className="cancel-button-modal" onClick={handleClose}>
            Cancelar
          </Button>
          <Button className="accept-button-modal" onClick={handleUpdate} disabled={snackbarOpen}>
            Actualizar
          </Button>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        message="Formador actualizado exitosamente"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        ContentProps={{
          style: { backgroundColor: 'green' }
        }}
      />
    </div>
  );
}

export default UpdateFormadorModal;
