import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const createGruposBasicos = async (token: any): Promise<any> => {
  try {
    await axios.post(`${apiUrl}/api/grupo/create/basico`, token, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const responseData = {
      success: true
    };
    return responseData;
  } catch (error) {
    console.error('Error al enviar el token de verificación:', error);
  }
};
