export const asistenciaOptions = ['true', 'false'];

export const rutaAprendizajeOptions = [
  'ADMINISTRADOR SUCURSAL BASICO',
  'ADOS BASICO',
  'AOS BASICO',
  'ASESOR CI BASICO',
  'ASESOR DE NEGOCIO YASTAS BASICO',
  'CAJERO BASICO',
  'ESEC SUCURSAL BASICO',
  'GERENTE OS BASICO',
  'GESTOR DE COBRANZA BASICO',
  'LIDER GRUPAL BASICO',
  'PROMOTOR CREDITO GRUPAL BASICO',
  'SUBGERENTE CI BASICO',
  'SUBGERENTE GRUPAL BASICO'
];

export const modalidadCursoOptions = ['PRESENCIAL', 'EN LINEA'];

export const regiosYastasOptions = [
  'CENTRO ORIENTE',
  'GOLFO',
  'METRO ORIENTE',
  'NORTE',
  'NORTE ATLANTICO',
  'NORTE PACIFICO',
  'OCCIDENTE',
  'OAXACA',
  'PENINSULA'
];
