import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const getCapacitaciones = async (
  token: string,
  filters: { 
    limit?: number; 
    page?: number; 
    nombre?: string; 
    puesto?: string; 
    direccion?: string; 
    subdireccion?: string; 
    os?: string;
    nomina?: string;
  } = { limit: 10, page: 1 } 
): Promise<any[]> => {
  try {
    const queryParams = new URLSearchParams({
      limit: filters.limit?.toString() || '10',
      page: filters.page?.toString() || '1',
      nombre: filters.nombre || '',
      puesto: filters.puesto || '',
      direccion: filters.direccion || '',
      subdireccion: filters.subdireccion || '',
      os: filters.os || '',
      nomina: filters.nomina || '',
    }).toString();

    const response = await axios.get(`${apiUrl}/api/capacitacion?${queryParams}`, {
      headers: { Authorization: `Bearer ${token}` }
    });

    if (response.status === 200 && response.data) {
      return response.data.data;
    } else {
      throw new Error(`Error, ${response.status}`);
    }
  } catch (error) {
    console.error('Error al obtener los datos:', error);
    throw error;
  }
};
