import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

interface FormadorData {
  nomina: string;
  os: string;
  subdireccion: string;
  estado_os: string;
}

export const agregarFormador = async (token: string, formadorData: FormadorData): Promise<any> => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/lider/formadores/create`,
      formadorData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.status === 200 || response.status === 201) {
      console.log('Formador agregado exitosamente');
      return response.data;
    } else {
      throw new Error(`Error, ${response.status}`);
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error;
      if (axiosError.response) {
        console.error('Error del API:', axiosError.response.status, axiosError.response.data);
      } else {
        console.error('Error de red:', axiosError.message);
      }
    }
    throw error;
  }
};