import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './menu.css';
import useUser from '../../services/useUser';
import SubMenu from './subMenu';

type MenuComponentProps = {
  menuState: boolean;
  isMobile?: boolean;
}

interface MenuItemProps {
  to: string;
  iconClass: string;
  text: string;
  handleSubMenuActive: (submenu: string) => void;
}

const MenuItem: React.FC<MenuItemProps> = ({ to, iconClass, text, handleSubMenuActive }) => (
  <li onClick={() => handleSubMenuActive(text)}>
    <NavLink to={to} className={({ isActive }) => (isActive ? 'active' : '')}>
      <span className={`icon ${iconClass}`}></span>
      {text}
    </NavLink>
  </li>
);

function Menu({ 
  menuState,
  isMobile
}: MenuComponentProps) {
  const { userData } = useUser();
  const [activeSubMenu, setActiveSubMenu] = useState('');

  const handleSubMenuActive = (submenu: string) => {
    setActiveSubMenu(submenu);
    if (!submenu) {
      setActiveSubMenu('');
    }
  };

  return (
    <div className='bootstrap-wrapper'>
      <div className={`${menuState ? 'menu-collapsed' : 'menu'} ${isMobile === true ? 'hidden-md-down': ''}`}>
        <ul className="menu-list">
          {userData?.role === 'admin' && (
            <MenuItem
              to="/colaboradores"
              iconClass="people-icon"
              text={menuState ? '' : 'Colaboradores'}
              handleSubMenuActive={handleSubMenuActive}
            />
          )}
          {userData && (userData.role === 'admin' || userData.role === 'lider' || userData?.role === 'formador') && (
            <SubMenu
              to="/grupos"
              isExpanded={menuState}
              toggleLabel={menuState ? '' : 'Grupos'}
              iconClass="groups-icon"
              links={[
                { to: '/grupos/basico', text: 'Básico' },
                { to: '/grupos/intermedio', text: 'Intermedio' },
                { to: '/grupos/avanzado', text: 'Acreditación' },
              ]}
              handleSubMenuActive={handleSubMenuActive}
              activeSubMenu={activeSubMenu}
              menuState={menuState}
            />
          )}
          {userData?.role === 'lider' && (
            <MenuItem
              to="/formadores"
              iconClass="leaders-icon"
              text={menuState ? '' : 'Formadores'}
              handleSubMenuActive={handleSubMenuActive}
            />
          )}
          <MenuItem
            to="/trackAnual"
            iconClass="report-icon"
            text={menuState ? '' : 'Track Capacitación'}
            handleSubMenuActive={handleSubMenuActive}
          />
          {userData?.role !== 'gerente' && (
            <><MenuItem
              to="/sedes"
              iconClass="headquarter-icon"
              text={menuState ? '' : 'Sedes'}
              handleSubMenuActive={handleSubMenuActive} /><MenuItem
                to="/hoteles"
                iconClass="headquarter-icon"
                text={menuState ? '' : 'Hoteles'}
                handleSubMenuActive={handleSubMenuActive} /></>
          )}
          {userData?.role === 'formador' && (
            <MenuItem
              to="/agenda"
              iconClass="calendar-icon"
              text={menuState ? '' : 'Agenda'}
              handleSubMenuActive={handleSubMenuActive}
            />
          )}
          {userData?.role === 'admin' && (
            <>
              <MenuItem
                to="/yastas"
                iconClass="yastas-icon"
                text={menuState ? '' : 'Yastas'}
                handleSubMenuActive={handleSubMenuActive}
              />
              <SubMenu
                to="/lyf"
                isExpanded={menuState}
                toggleLabel={menuState ? '' : 'Líderes y Formadores'}
                iconClass="leaders-icon"
                links={[
                  // { to: '/lyf/lyf_dashboard', text: 'Vista Principal' },
                  { to: '/lyf/lyf_gestion', text: 'Gestión Líderes y Formadores' },
                ]}
                handleSubMenuActive={handleSubMenuActive}
                activeSubMenu={activeSubMenu}
                menuState={menuState}
              />
            </>
          )}
        </ul>
      </div>
    </div>
  );
}

export default Menu;