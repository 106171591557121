// Importa las dependencias necesarias para el componente.
import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../assets/down-chevron-svgrepo-com.svg';
import './menu.css'; // Importa los estilos CSS para el menú.

// Define las propiedades (props) que el componente SubMenu espera recibir.
interface SubMenuProps {
    isExpanded: boolean; // Estado inicial del submenú (expandido o no).
    toggleLabel: string; // El texto que se muestra para el submenú.
    iconClass: string; // Clase CSS para el icono del submenú.
    to: string;
    links: { to: string; text: string }[]; // Array de objetos que representan los links del submenú.
    handleSubMenuActive: (submenu: string) => void; // Función para manejar el estado activo del submenú.
    activeSubMenu: string; // Indica cuál submenú está actualmente activo.
    menuState: boolean;
}

// Define el componente SubMenu utilizando una función de componente.
const SubMenu: React.FC<SubMenuProps> = ({
    isExpanded, // Propiedad que indica si el submenú está inicialmente expandido.
    toggleLabel, // Texto para el submenú.
    iconClass, // Clase CSS para el icono del submenú.
    to,
    links, // Array de links que pertenecen al submenú.
    handleSubMenuActive, // Función para actualizar el estado activo del submenú.
    activeSubMenu, // Indica cuál submenú está activo.
    menuState,
}) => {
    const [expanded, setExpanded] = useState(isExpanded); // Estado local para controlar la expansión del submenú.
    const { pathname } = useLocation(); // Obtenemos la ruta actual usando el hook useLocation
    //console.log(activeSubMenu, toggleLabel);
    // console.log('texto de menu activo', toggleLabel);

    // Función para manejar la expansión y activación del submenú.
    const handleToggle = () => {
        const isActivating = !expanded;
        setExpanded(isActivating);
        //console.log('esta expandido?', isActivating)
        handleSubMenuActive(isActivating ? toggleLabel : '');
    };

    // Verificar si algún link del submenú está activo
    const isAnyLinkActive = links.some(link => pathname === link.to);

    // Aplicar la clase 'active' al menú encabezado si el submenú está activo
    const isActive = activeSubMenu === toggleLabel || isAnyLinkActive;
    // Determinar si se añade la clase 'expanded' para la rotación de la flecha
    // Determinar si se añade la clase 'expanded' para la rotación de la flecha
    let menuItemClasses;
    if (menuState) {
        menuItemClasses = '';
    } else {
        menuItemClasses = `menu-item-with-submenu ${expanded ? 'expanded' : ''} ${isActive ? 'active' : ''}`;
    }
    return (
        <li className={`${menuState ? 'menu-collapsed' : 'menu-wrapper '}`}>
            <NavLink to={to}
                className={menuItemClasses}
                onClick={handleToggle}
            >
                {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}> */}
                <span className={`icon ${iconClass} ${menuState ? 'active' : ''}`}></span>
                <span>{toggleLabel}</span>
                {!menuState && <Arrow className={`arrow ${expanded ? 'active' : ''}`} />}
            </NavLink>
            {expanded && !menuState && (
                <ul className="submenu-list">
                    {links.map((link, index) => (
                        <li key={index}>
                            <NavLink
                                to={link.to}
                                className={({ isActive }) => isActive ? 'group-active' : ''}
                                onClick={() => handleSubMenuActive(toggleLabel)}
                            >
                                {link.text}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            )}
        </li>
    );
};

export default SubMenu;