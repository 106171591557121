import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const createGruposNiveles = async (token: string, tipoCurso: string): Promise<any> => {
    try {
        const response = await axios.post(`${apiUrl}/api/grupo/create/niveles`,
            { tipo_curso: tipoCurso },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        const responseData = {
            success: true,
            data: response.data
        };
        return responseData;
    } catch (error) {
        console.error('Error al crear grupos por niveles:', error);
    }
};

export const createGruposIntermedio = async (token: string, tipoCurso: string): Promise<any> => {
    try {
        const response = await axios.post(`${apiUrl}/api/grupo/create/intermedio`,
            { tipo_curso: tipoCurso },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        const responseData = {
            success: true,
            data: response.data
        };
        return responseData;
    } catch (error) {
        console.error('Error al crear grupos por niveles:', error);
    }
};

export const createGruposAvanzado = async (token: string, tipoCurso: string): Promise<any> => {
    try {
        const response = await axios.post(`${apiUrl}/api/grupo/create/avanzado`,
            { tipo_curso: tipoCurso },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        const responseData = {
            success: true,
            data: response.data
        };
        return responseData;
    } catch (error) {
        console.error('Error al crear grupos por niveles:', error);
    }
};