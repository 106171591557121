import axios, { AxiosResponse, AxiosError } from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const eliminarFormador = async (token: string, nominaId: string): Promise<any> => {
    try {
        const response: AxiosResponse = await axios.delete(`${apiUrl}/api/lider/formadores/delete`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            data: {
                nomina: nominaId
            }
        });

        if (response.status === 200 || response.status === 204) {
            console.log('Formador eliminado exitosamente.');
            return response.data;
        } else {
            throw new Error(`Error al eliminar formador, ${response.status}`);
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError;
            if (axiosError.response) {
                console.error('Error del API al eliminar formador:', axiosError.response.status, axiosError.response.data);
            } else {
                console.error('Error de red al eliminar formador:', axiosError.message);
            }
            throw error;
        }
    }
};