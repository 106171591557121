import axios, { CancelTokenSource } from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

type UploadProgressCallback = (percentage: number) => void;
type UploadErrorCallback = (errorMessage: string) => void;

export const uploadColaboradores = async (
  document: { file: string },
  token: string,
  onProgress?: UploadProgressCallback,
  onError?: UploadErrorCallback,
  cancelTokenSource?: CancelTokenSource,
  timeout = 30000 // 30 segundos por defecto
) => {
  try {
    const response = await axios.post(`${apiUrl}/api/colaborador/upload-excel`, document, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          if (onProgress) onProgress(percentCompleted);
        }
      },
      cancelToken: cancelTokenSource?.token,
      timeout
    });

    if (response.status === 200) {
      return response;
    } else {
      throw new Error(`Error, ${response.status}`);
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Upload cancelled or timeout reached', error.message);
      if (onError) onError('La carga fue cancelada por el usuario o el tiempo de espera ha excedido.');
    } else {
      console.error('Error uploading the file.', error);
      if (onError) onError('Error al subir el archivo.');
    }
    return null;
  }
};