import React, { useState, useEffect } from 'react';
import format from 'date-fns/format';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import UploadModal from '../../components/uploadModal/uploadModal';
import { getAgenda } from '../../services/getAgenda';
import { getActividades } from '../../services/getActividades';
import { getExperiencia } from '../../services/getExperiencia';
import { downloadAgenda } from '../../services/downloadAgenda';
import { getFormadoresLigados } from '../../services/getFormadoresLigados';
import { uploadExcelAgenda } from '../../services/uploadExcelAgenda';
import UserFallback from '../../assets/user-fallback-black.svg';
import Download from '../../assets/download-logo.png';
import SuccessLogo from '../../assets/success-logo.png';
import Upload from '../../assets/upload-logo.png';
import Quit from '../../assets/quit.png';
import ErrorLogo from '../../assets/error-logo.png';
import QuestionMark from '../../assets/circle-info.svg';
import { AppState, AgendaItem, ActividadItem, ExperienciaItem, Formador } from '../../types/types';
import agendaStyles from '../../styles/agenda.module.css';
import { startOfWeek, endOfWeek } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import BreadCrumb from '../../components/breadCrumb/breadCrumb';
import moment from 'moment';

moment.updateLocale('en', {
  months: ['Enero', 'Febreo', 'Marzo', 'Abril', 'Mayo', 'Junio', 
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 
      'Viernes', 'Sábado']
})

const DateFormat = 'YYYY-MM-DD';

function Agenda() {
  const { id } = useParams();
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [actionName, setActionName] = useState<string>("");
  const [uploadingMessage, setUploadingMessage] = useState("Cargando Datos...");
  const [agenda, setAgenda] = useState<AgendaItem[]>([]);
  const [actividades, setActividades] = useState<ActividadItem[]>([]);
  const [experiencias, setExperiencias] = useState<ExperienciaItem[]>([]);
  const [formador, setFormador] = useState<Formador | null>(null);
  const [liderFormadorDireccion, setLiderFormadorDireccion] = useState<string | null>("");
  const [startDate, setStartDate] = useState<Date | null>(startOfWeek(new Date(), { weekStartsOn: 1 }));
  const [endDate, setEndDate] = useState<Date | null>(endOfWeek(new Date(), { weekStartsOn: 1 }));

  const usuarioId = useSelector((state: AppState) => state.user.userData?.id);
  const usuarioNombre = useSelector((state: AppState) => state.user.userData?.nombre);
  const usuarioDireccion = useSelector((state: AppState) => state.user.userData?.direccion);
  const usuarioUbicacion = useSelector((state: AppState) => state.user.userData?.ubicacion);
  const usuarioRol = useSelector((state: AppState) => state.user.userData?.role);
  const token = sessionStorage.getItem('token');

  const crumbs = [
    { path: '/formadores', title: 'Formadores' },
    { path: '/reportes', title: 'Detalle de agenda' },
  ];

  const closeAlert = () => {
    setIsUploading(false);
    setUploadSuccess(false);
    setUploadError(false);
  };

  const closeLoadingAlert = () => {
    setLoadingData(false);
  };

  const handleCargarAgendaClick = () => {
    setActionName("Agenda");
    setUpdateModal(true);
  };

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    const formadorId = id ? Number(id) : usuarioId;

    if (token && formadorId) {
      getFormadoresLigados(token)
        .then((response) => {
          const formadorEncontrado = response.data.formadores.find((f: Formador) => f.formador_id === formadorId);
          const liderFormadorDireccion = response.data.direccion;

          if (formadorEncontrado) {
            setFormador(formadorEncontrado);
            setLiderFormadorDireccion(liderFormadorDireccion);
          } else {
            console.log('Formador no encontrado');
          }
        })
        .catch((error) => {
          console.error('Error al obtener formadores', error);
        });

      getActividades(token).then((data) => {
        setActividades(data.actividades);
      }).catch((error) => {
        console.error('Error al obtener las actividades', error);
      });

      getExperiencia(token, formadorId).then((data) => {
        setExperiencias(data.experiencia);
      }).catch((error) => {
        console.error('Error al obtener las experiencias', error);
      });
    }
  }, [id, usuarioId, token]);

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    const formadorId = id ? Number(id) : usuarioId ? Number(usuarioId) : null;
    if (token && formadorId && startDate && endDate) {
      const fechaInicio = format(startDate, 'yyyy-MM-dd');
      const fechaFin = format(endDate, 'yyyy-MM-dd');
      getAgenda(token, formadorId, fechaInicio, fechaFin)
        .then((data) => {
          setAgenda(data.agenda);
        })
        .catch((error) => {
          console.error('Error al obtener la agenda', error);
        });
    }
  }, [id, usuarioId, token, startDate, endDate]);

  const renderSymbol = (tipo_experiencia: string) => {
    return tipo_experiencia === "no tiene" ? "❌" : "✅";
  };

  const renderTarjetasPorDia = () => {
    const datesMap: any = {};

    agenda?.forEach((item: AgendaItem) => {
      const {
        fecha_inicio,
        fecha_fin
      } = item;

      const final_moment = moment.utc(fecha_fin);
      const var_mom = moment.utc(fecha_inicio);

      while (final_moment.diff(var_mom, 'days') >= 0) {
        const date_str: string = var_mom?.format(DateFormat);

        if (!datesMap?.[date_str]) datesMap[date_str] = {
          formatted_date: var_mom?.format('dddd DD [de] MMMM'),
          columns: []
        };

        datesMap[date_str]['columns']?.push({
          ...item,
          hour_epoch: moment(item?.hora_inicio, 'HH:mm:ss').toDate().getTime()
        });

        var_mom?.add(1, 'days');
      }
    });

    return Object.keys(datesMap).length > 0 ? (
      Object.keys(datesMap).map((key: string, ix: number) => {
        const columns: any[] = datesMap?.[key]?.columns || [];

        return (
          <div key={ix} className={agendaStyles.card}>
            <p className={agendaStyles.pBoldBlack}>
              {datesMap?.[key]?.formatted_date}
            </p>
            <div className={agendaStyles.flexColumnLeft}>
              {columns?.sort((a: any, b: any) => (a?.hour_epoch - b?.hour_epoch))?.
                map((item: any) => (
                <div key={item.agenda_id} className="flexRowLeftAlign" style={{ margin: '5px 0' }}>
                  <div style={{ display: 'flex' }}>
                    <span className={agendaStyles.circuloColor} style={{ backgroundColor: item.color }}></span>
                    <div>
                      <p className={agendaStyles.pItemActividad}>{item.actividad}</p>
                      <span className={agendaStyles.pItemHora}>{item.hora_inicio} - {item.hora_fin}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })
    ) : (
      <div className={agendaStyles.card}>
        <div className={agendaStyles.flexColumnLeft}>
          <div className={agendaStyles.cardColumn_noData}>
            <div className="flexRowLeftAlign" style={{ margin: '5px 0', justifyContent: 'center' }}>
              No se encontraron datos
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Función para obtener el formato de fecha deseado
  const getFormattedWeek = (startDate: Date) => {
    const start = startOfWeek(startDate, { weekStartsOn: 1, locale: esLocale });
    const end = endOfWeek(startDate, { weekStartsOn: 1, locale: esLocale });

    const formattedStartDay = format(start, "dd", { locale: esLocale });
    const formattedEndDay = format(end, "dd", { locale: esLocale });
    const formattedMonth = format(start, "MMMM", { locale: esLocale });
    const formattedYear = format(start, "yyyy", { locale: esLocale });

    // Asegúrate de que no estás poniendo el mes si no es necesario
    const isSameMonth = start.getMonth() === end.getMonth();

    return isSameMonth
      ? `${formattedStartDay}-${formattedEndDay} de ${formattedMonth} del ${formattedYear}`
      : `${formattedStartDay} de ${format(start, "MMMM", { locale: esLocale })} - ${formattedEndDay} de ${format(end, "MMMM", { locale: esLocale })} del ${formattedYear}`;
  };


  // Aquí debes asegurarte de que startDate sea una Date y no null
  const onWeekSelect = (date: Date | null) => {
    if (date) {
      const start = startOfWeek(date, { weekStartsOn: 1, locale: esLocale });
      const end = endOfWeek(date, { weekStartsOn: 1, locale: esLocale });

      setStartDate(start);
      setEndDate(end);
      setIsDatePickerVisible(false);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '49%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    outline: 'none',
  };

  function formatName(fullName: string) {
    const nameParts = fullName.trim().split(' ');
    if (nameParts.length > 1) {
      const apellidoIndex = nameParts.length - 2;
      return `${nameParts[0]} ${nameParts[apellidoIndex]}`;
    } else {
      return fullName;
    }
  }

  const download = () => {
    const token = sessionStorage.getItem('token');
    const formadorId = id ? Number(id) : usuarioId;

    if (token && formadorId && startDate && endDate) {
      const formattedStartDate = format(startDate, 'yyyy-MM-dd');
      const formattedEndDate = format(endDate, 'yyyy-MM-dd');

      downloadAgenda(token, formadorId, formattedStartDate, formattedEndDate)
        .then((result) => {
          // Aquí asumimos que result contiene la URL de descarga
          window.open(result.url);
        })
        .catch((error) => {
          console.error('Error al descargar la agenda', error);
        });
    } else {
      console.error('Fechas no seleccionadas, token no disponible o ID de formador no especificado');
    }
  };

  const onClose = () => {
    setUpdateModal(false);
  };

  const handleFileUpload = async (base64Data: string, fileName: string) => {
    setLoadingData(true);
    setIsUploading(true);
    setUploadingMessage("Cargando Datos...");

    setTimeout(() => {
      setLoadingData(false);
      setUploadingMessage("Se subirá el excel pronto");

      setTimeout(async () => {
        const token = sessionStorage.getItem('token');

        if (!token) {
          setErrorMessage('Error: No se encontró el token de sesión.');
          setUploadError(true);
          setIsUploading(false);
          setTimeout(closeAlert, 2000);
          return;
        }

        let response;
        try {
          if (actionName === "Agenda") {
            response = await uploadExcelAgenda(base64Data, token);
          } else {
            throw new Error("Acción no reconocida");
          }

          setIsUploading(false);

          if (response && response.success) {
            setSuccessMessage(`La subida del archivo ${fileName} fue exitosa.`);
            setUploadSuccess(true);
          } else {
            setErrorMessage(response.message || 'Error en la carga del archivo.');
            setUploadError(true);
          }
        } catch (error) {
          const errorMessage = error instanceof Error ? error.message : 'Error inesperado en la carga del archivo.';
          setErrorMessage(errorMessage);
          setUploadError(true);
        } finally {
          setTimeout(closeAlert, 2000);
        }
      }, 2000);
    }, 2000);
  };

  return (
    <div className={agendaStyles["container"]}>
      {updateModal && (
        <UploadModal
          onClose={onClose}
          uploadService={handleFileUpload}
          title={`Cargar Archivo de ${actionName.charAt(0).toUpperCase() + actionName.slice(1)}`}
          isLoading={isUploading}
          setIsLoading={setLoadingData}
          successCallback={(message) => {
            console.log(message);
          }}
          errorCallback={(message) => {
            console.error(message);
          }}
        />
      )}
      <div className={agendaStyles["sub-container"]}>
        {loadingData === true && (
          <div className="update-file-response-container">
            <div className="update-file-message loading-data">
              <img src={SuccessLogo} alt="success-logo" />
              <p>Cargando Datos...</p>
              <img src={Quit} alt="quit-logo" onClick={closeLoadingAlert} className="close-alert" />
            </div>
          </div>
        )}
        {isUploading && (
          <div className="update-file-response-container-info">
            <div className="update-file-message">
              <img src={QuestionMark} />
              <p>{uploadingMessage}</p>
              <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
            </div>
          </div>
        )}
        {(uploadSuccess === true) && (
          <div className="update-file-response-container-success">
            <div className="update-file-message update-file-success">
              <img src={SuccessLogo} alt="success-logo" />
              <p>{successMessage}</p>
              <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
            </div>
          </div>
        )}
        {(uploadError === true) && (
          <div className="update-file-response-container-error">
            <div className="update-file-message update-file-error">
              <img src={ErrorLogo} alt="error-logo" />
              <p>{errorMessage}</p>
              <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
            </div>
          </div>
        )}
        {usuarioRol === "lider" && <BreadCrumb crumbs={crumbs} />}
        <div className={agendaStyles["formador-card-container"]}>
          <div className={agendaStyles["flexRowCenter"]}>
            <img src={UserFallback} height={48} width={48} />
            <div className="flexColumnLeft">
              <p className={agendaStyles["pBoldBlack"]}>
                {id && formador?.nombre
                  ? formatName(formador.nombre)
                  : usuarioNombre
                    ? formatName(usuarioNombre)
                    : 'Cargando nombre...'}
              </p>
              <p className={agendaStyles["formador"]}>Formador</p>
              {startDate && (
                <div className={agendaStyles["pBoldBlack"]}>
                  {getFormattedWeek(startDate)}
                </div>
              )}
            </div>
          </div>
          <div className={agendaStyles["fecha-container"]}>
            <div className="fecha-grupos">
              <div className="date-picker-container">
                <p className={agendaStyles["pBoldBlack"]}>
                  Dirección: <span className={agendaStyles["values"]}>{liderFormadorDireccion || usuarioDireccion}</span>
                </p>
                <p className={agendaStyles["pBoldBlack"]}>Subdirección: <span className={agendaStyles["values"]}>{formador?.direccion || usuarioUbicacion}</span></p>
                <Button
                  variant="outlined"
                  onClick={() => setIsDatePickerVisible(true)}
                  className={agendaStyles["select-week-button"]}
                >
                  Selecciona la fecha
                </Button>

                <Modal
                  open={isDatePickerVisible}
                  onClose={() => setIsDatePickerVisible(false)}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={modalStyle}>
                    <Box
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                      }}
                    >
                      <Button sx={{
                        minWidth: 0,
                        padding: 0,
                      }}
                        onClick={() => setIsDatePickerVisible(false)}>
                        <CloseIcon />
                      </Button>
                    </Box>
                    <DatePicker
                      id="picker"
                      selected={startDate}
                      onChange={onWeekSelect}
                      startDate={startDate}
                      endDate={endDate}
                      onWeekSelect={onWeekSelect}
                      showWeekNumbers
                      shouldCloseOnSelect={false}
                      inline
                      locale={esLocale}
                      dateFormat="MMMM dd"
                      className="hidden-datepicker"
                    />
                  </Box>
                </Modal>
              </div>
            </div>
          </div>
          <div className={agendaStyles["flexRowCenter-Left"]}>
            {usuarioRol === "formador" && (
              <div className={agendaStyles["imgPointer"]} onClick={handleCargarAgendaClick}>
                <img src={Upload} alt="upload-logo" />
                <p
                  style={{
                    marginLeft: '5px',
                  }}>Actualizar Agenda</p>
              </div>
            )}
            <div className={agendaStyles["imgPointer"]} onClick={download}>
              <img src={Download} alt="upload-logo" />
              <p
                style={{
                  marginLeft: '5px',
                }}>DESCARGAR</p>
            </div>
          </div>
        </div>
        <div className={agendaStyles["main-content"]}>
          <div className={`${agendaStyles["grid-container"]} ${agendaStyles["scrollable"]}`}>
            {renderTarjetasPorDia()}
          </div>
        </div>
      </div>
      <div className={`${agendaStyles.sidebar} ${agendaStyles.scrollable}`}>
        <aside className={agendaStyles.actividades}>
          <h3 className={agendaStyles.actividadesHeader}>Actividades</h3>
          <ul className={agendaStyles.actividadesList}>
            {actividades.map((actividad) => (
              <li key={actividad.id} className={agendaStyles.actividadItem}>
                <span className={agendaStyles.actividadColor} style={{ backgroundColor: actividad.color }}></span>
                {actividad.actividad}
              </li>
            ))}
          </ul>
        </aside>
        <aside className={agendaStyles.experiencia}>
          <h3 className={agendaStyles.experienciaHeader}>Experiencia</h3>
          <ul className={agendaStyles.experienciasList}>
            {experiencias.map((experiencia, index) => (
              <li key={index} className={agendaStyles.experienciaItem}>
                {renderSymbol(experiencia.tipo_experiencia)} {experiencia.experiencia}
              </li>
            ))}
          </ul>
        </aside>
      </div>
    </div>
  );
}

export default Agenda;