import React, { useEffect, useState } from 'react';
import './editGroupsFormador.css';
import Quit from '../../assets/quit.png';
import 'react-datepicker/dist/react-datepicker.css';
import EditDataSelectorDireccion from '../editDataSelectors/editDataSelectorDireccion';
import EditDataSelectorSubdireccion from '../editDataSelectors/editDataSelectorSubdirecciones';
import { getFormadores } from '../../services/getFormadores';

function EditGroupsFormador({ isAsignarFormador, grupo, colaborador, selectedDate }: any) {
  const [newDireccion, setNewDireccion] = useState<string>('');
  const [newSubdireccion, setNewSubdireccion] = useState<string>('');


  const closeEditUserModal = () => {
    isAsignarFormador(false);
  };

  const getDireccion = (direccion: string) => {
    setNewDireccion(direccion);
  };
  const getSubdireccion = (subdireccion: string) => {
    setNewSubdireccion(subdireccion);
  };

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    const apiCall = () => {
      if (token) {
        getFormadores(token)
          .then((result) => {
            console.log(result);
          })
          .catch((error) => {
            console.error('Error al obtener usuario', error);
          });
      }
    };
    apiCall();
  });

  return (
    <div className="colabs-filter-modal">
      <div className="colabs-filter-container asignar-formador">
        <div className="colabs-filter-header">
          <p>Asignar Formador</p>
          <img src={Quit} alt="quit-logo" onClick={closeEditUserModal} />
        </div>
        <div className="edit-option">
          <p className="colabs-filter-option">Ubicación</p>
          <div className="direccion-selector">
            <p>Dirección</p>
            <EditDataSelectorDireccion getDireccion={getDireccion} />
          </div>
          <div className="direccion-selector subdireccion-selector">
            <p>Subdirección</p>
            <EditDataSelectorSubdireccion getSubdireccion={getSubdireccion} />
          </div>
        </div>
        <div className="upload-file-buttons">
          <button className="cancel-button" onClick={closeEditUserModal}>
            CANCELAR
          </button>
          <button className="accept-button">GUARDAR CAMBIOS</button>
        </div>
      </div>
    </div>
  );
}

export default EditGroupsFormador;
