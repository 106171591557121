import React, { useState } from 'react';
import Upload from '../../assets/upload-logo.png';
import { uploadYastas } from '../../services/uploadYastas';

function UpdateFormadoresModal({
  isUpdateFormadoresModal,
  isPostSuccess,
  isLoadingAlert
}: any) {
  const changeModalState = () => {
    isUpdateFormadoresModal(false);
  };

  return (
    <div className="updatedb-modal">
      <div className="updatedb-container">
        <p>Agregar formador</p>
        <div className="upload-file-buttons">
          <button className="cancel-button" onClick={changeModalState}>
            CANCELAR
          </button>
          <button className="accept-button" onClick={() => {console.log("DATA")}}>
            ACEPTAR
          </button>
        </div>
      </div>
    </div>
  );
}

export default UpdateFormadoresModal;
