export const getUserSuccess = (userData) => {
    return {
      type: 'GET_USER_SUCCESS',
      payload: userData,
    };
  };
  
  export const getUserFailure = (error) => {
    return {
      type: 'GET_USER_FAILURE',
      payload: error,
    };
  };  