import React, { useEffect, useState } from 'react';
import '../styles/styles.css';
import MenuImage from '../assets/menu-example.svg';
import Two from '../assets/Two.svg';
import Three from '../assets/Three.svg';
import LeftArrow from '../assets/circle-arrow-left.svg';
import RightArrow from '../assets/circle-arrow-right.svg';

import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import { useSelector } from 'react-redux';
import { AppState } from '../types/types';

interface ImageType {
  original: string;
  thumbnail: string;
  originalHeight: number;
}
interface SlideText {
  title: string;
  description: React.ReactNode;
  texto: React.ReactNode;
}

function Home() {
  const rolUsuario = useSelector((state: AppState) => state.user.userData?.role);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slideTexts, setSlideTexts] = useState<SlideText[]>([]);
  const [images, setImages] = useState<ImageType[]>([]);

  console.log('usuario rol actual', rolUsuario)

  useEffect(() => {
    let texts: SlideText[] = [];

    if (rolUsuario === 'gerente') {
      setImages([
        {
          original: MenuImage,
          thumbnail: MenuImage,
          originalHeight: 180
        }
      ]);
    } else {
      setImages([
        {
          original: MenuImage,
          thumbnail: MenuImage,
          originalHeight: 180
        },
        {
          original: Two,
          thumbnail: Two,
          originalHeight: 180
        },
        {
          original: Three,
          thumbnail: Three,
          originalHeight: 180
        }
      ]);
    }

    if (rolUsuario === 'admin') {
      texts = [
        {
          title: "¡Bienvenido a GAC!",
          description: "GAC es tu herramienta que te ayudará para la gestión de la capacitación que ofrecemos como COE.",
          texto: (
            <>
              Te ayuda a agilizar la generación de <strong>listas de grupos de los niveles Básico, Intermedio y Avanzado</strong>.
            </>
          ),
        },
        {
          title: "¡Bienvenido a GAC!",
          description: (
            <>
              Actualiza el estatus de los colaboradores en su plan de capacitación semestral, <strong>Track Capacitación</strong>, para que pueda ser visualizado por el GOS.
            </>
          ),
          texto: ""
        },
        {
          title: "¡Bienvenido a GAC!",
          description: (
            <>
              Concentra los datos de <strong>Sedes</strong>, <strong>Hoteles</strong>, <strong>Líderes</strong> y <strong>Formadores</strong> para contener esta información en un solo <strong>lugar</strong>.
            </>
          ),
          texto: ""
        }
      ];
    } else if (rolUsuario === 'lider') {
      texts = [
        {
          title: "¡Bienvenido a GAC!",
          description: "Guía a tu equipo hacia el éxito.",
          texto: (
            <>
              Visualiza en un solo lugar las <strong>listas de grupos de los niveles Básico, Intermedio y Avanzado.</strong>
            </>
          )
        },
        {
          title: "¡Bienvenido a GAC!",
          description: (
            <>
              Revisa el estatus de un colaborador en su plan de capacitación semestral, <strong>Track Capacitación</strong>.
            </>
          ),
          texto: ""
        },
        {
          title: "¡Bienvenido a GAC!",
          description: (
            <>
              Cuentas con los datos de Sedes y Hoteles en un solo lugar, además <strong>visualiza la agenda de cada uno de tus formadores</strong>.
            </>
          ),
          texto: ""
        }
      ];
    } else if (rolUsuario === 'formador' || rolUsuario === 'colaborador') {
      texts = [
        {
          title: "¡Bienvenido a GAC!",
          description: "GAC es tu herramienta que te ayudará para la gestión de la capacitación que ofrecemos como COE.",
          texto: (
            <>
              Visualiza en un solo lugar las <strong>listas de grupos de los niveles Básico, Intermedio y Avanzado.</strong>
            </>
          )
        },
        {
          title: "¡Bienvenido a GAC!",
          description: (
            <>
              Revisa el estatus de un colaborador en su plan de capacitación semestral, <strong>Track Capacitación</strong>.
            </>
          ),
          texto: ""
        },
        {
          title: "¡Bienvenido a GAC!",
          description: (
            <>
              Cuentas con los datos de Sedes y Hoteles en un solo lugar, carga la actualización de tu <strong>agenda</strong>.
            </>
          ),
          texto: ""
        }
      ];
    } else if (rolUsuario === 'gerente') {
      texts = [
        {
          title: "¡Bienvenido a GAC!",
          description: "GAC es tu herramienta que te ayudará para la gestión de la capacitación de tus colaboradores.",
          texto: (
            <>
              Revisa el estatus de un colaborador en su plan de capacitación semestral, <strong>Track Capacitación</strong>.
            </>
          )
        },
      ];
    }
    setSlideTexts(texts);
  }, [rolUsuario]);

  const onSlide = (index: React.SetStateAction<number>) => {
    setCurrentIndex(index);
  };

  return (
    <div className="container-onboarding">
      <div className="container-carousel">
        {slideTexts.length > 0 && currentIndex < slideTexts.length && ( // Asegúrate de que el índice actual exista en slideTexts
          <div className="text-above-gallery">
            <h1>{slideTexts[currentIndex].title}</h1>
            <p>{slideTexts[currentIndex].description}</p>
            {slideTexts[currentIndex].texto && <p>{slideTexts[currentIndex].texto}</p>}
          </div>
        )}
        <ImageGallery
          items={images}
          showBullets
          showPlayButton={false}
          showNav
          showThumbnails={false}
          showFullscreenButton={false}
          startIndex={currentIndex}
          onSlide={onSlide}
          renderLeftNav={(onClick) => (
            <img style={{ filter: "none", paddingLeft: 84 }} className='image-gallery-icon image-gallery-left-nav' src={LeftArrow} onClick={onClick} height={48} width={48} />
          )}
          renderRightNav={(onClick) => (
            <img style={{ filter: "none", paddingRight: 84 }} className='image-gallery-icon image-gallery-right-nav' src={RightArrow} onClick={onClick} height={48} width={48} />
          )}
        />
      </div>
    </div>
  );
}

export default Home;