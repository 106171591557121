import React, { useState } from 'react';
import '../updateDbModal/updateDbModal.css';
import UploadIcon from '../../assets/upload-logo.png';

interface UploadModalProps {
  onClose: () => void;
  uploadService: (base64Data: string, fileName: string) => Promise<void>;
  title?: string;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  successCallback: (message: string) => void;
  errorCallback: (message: string) => void;
}

const UploadModal: React.FC<UploadModalProps> = ({
  onClose,
  uploadService,
  title = 'Cargar Archivo',
  isLoading,
  setIsLoading,
  successCallback,
  errorCallback
}) => {
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0] || null;
    setFile(selectedFile);
  };

  const handleUploadClick = async () => {
    if (!file) {
      alert('Por favor, selecciona un archivo primero.');
      return;
    }

    setIsLoading(true);
    const reader = new FileReader();

    reader.onload = async () => {
      try {
        const base64Data = (reader.result as string).split(',')[1];
        await uploadService(base64Data, file.name); 
        successCallback(`La subida del archivo ${file.name} fue exitosa.`);
      } catch (error) {
        errorCallback('Error en la carga del archivo.');
        console.error('Error al cargar archivo', error);
      } finally {
        setIsLoading(false);
        onClose();
      }
    };

    reader.readAsDataURL(file);
  };

  return (
    <div className="updatedb-modal">
      <div className="updatedb-container">
        <p>{title}</p>
        <div className="input-container">
          <div className="upload-file">
            <img src={UploadIcon} alt="upload-logo" />
            <p>{file === null ? 'Seleccionar archivo' : file.name}</p>
          </div>
          <input type="file" onChange={handleFileChange} />
        </div>
        <div className="upload-file-buttons">
          <button className="cancel-button" onClick={onClose}>Cancelar</button>
          <button className="accept-button" onClick={handleUploadClick} disabled={isLoading}>
            {isLoading ? 'Cargando...' : 'Aceptar'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default UploadModal;