import React, { useState } from 'react';
import Upload from '../../assets/upload-logo.png';
import { uploadYastas } from '../../services/uploadYastas';

function UpdateYastasModal({ isUpdateYastasModal, isPostSuccess, isLoadingAlert }: any) {
  const [colabsFile, setColabsFile] = useState<File | null>(null);
  const [colabsFileConverted, setColabsFileConverted] = useState<string>('');
  const [jsonFile, setJsonFile] = useState<any>(null);
  const [postReponse, setPostResponse] = useState<any>(null);
  //console.log('archivo seleccionado', colabsFile);
  //console.log('archivo convertidos x2', colabsFileConverted);
  //console.log('response', postReponse);

  const changeModalState = () => {
    isUpdateYastasModal(false);
  };

  //   const updateYastasList = () => {
  //     isPostSuccess
  //   }

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    const lector = new FileReader();
    lector.onload = () => {
      const fileConverted = lector.result as string;
      const base64File = fileConverted.split(',')[1];
      setColabsFileConverted(base64File);
      const objetoJson = {
        file: base64File
      };
      setJsonFile(objetoJson);
    };
    if (file) {
      lector.readAsDataURL(file);
    }
    setColabsFile(file);
  };

  const uploadColaboradoresYastasList = () => {
    isLoadingAlert(false);
    const token: any = sessionStorage.getItem('token');
    const document: any = jsonFile;
    uploadYastas(document, token)
      .then((response: any) => {
        setPostResponse(response);
        response.success === true ? isPostSuccess(true) : isPostSuccess(false);
        isLoadingAlert(true);
      })
      .catch((error) => {
        console.error('Error al cargar archivo', error);
      });
    changeModalState();
  };

  return (
    <div className="updatedb-modal">
      <div className="updatedb-container">
        <p>Actualizar Datos</p>
        <div className="input-container">
          <div className="upload-file">
            <img src={Upload} alt="upload-logo" />
            <p>{colabsFile === null ? 'Cargar archivo' : colabsFile.name}</p>
          </div>
          <input type="file" id="uploadDb" name="Cargar archivo" onChange={handleFile} />
        </div>
        <div className="upload-file-buttons">
          <button className="cancel-button" onClick={changeModalState}>
            CANCELAR
          </button>
          <button className="accept-button" onClick={uploadColaboradoresYastasList}>
            ACEPTAR
          </button>
        </div>
      </div>
    </div>
  );
}

export default UpdateYastasModal;
