import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const downloadCapacitaciones = async (
    token: string,
    filters: {
        nombre?: string;
        puesto?: string;
        direccion?: string;
        subdireccion?: string;
        os?: string;
        nomina?: string;
    } = {}
): Promise<any> => {
    try {
        const queryParams = new URLSearchParams({
            nombre: filters.nombre || '',
            puesto: filters.puesto || '',
            direccion: filters.direccion || '',
            subdireccion: filters.subdireccion || '',
            os: filters.os || '',
            nomina: filters.nomina || '',
        }).toString();

        const response = await axios.get(`${apiUrl}/api/capacitacion/download?${queryParams}`, {
            headers: { Authorization: `Bearer ${token}` }
        });

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(`Error, ${response.status}`);
        }
    } catch (error) {
        console.error('Error al descargar el archivo de capacitaciones:', error);
        throw error;
    }
};