import axios, { AxiosResponse, AxiosError } from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const getGrupos = async (
  token: string,
  rowsLimit: number | '',
  pagination: number,
  filter: string,
  selectedDate: string,
  endDate?: string,
  type?: string
): Promise<any> => {
  try {
    let response: AxiosResponse | null = null;

    if (type == "avanzado") {
      response = await axios.get(
        `${apiUrl}/api/grupo/avanzado/?limit=${rowsLimit}&page=${pagination}&fecha_inicio=${selectedDate}${endDate ? `&fecha_fin=${endDate}${filter}` : ''
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } else {
      response = await axios.get(
        `${apiUrl}/api/grupo/?limit=${rowsLimit}&page=${pagination}&fecha_inicio=${selectedDate}${endDate ? `&fecha_fin=${endDate}` : ''
        }&tipo_curso=${type}${filter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    }

    if (response?.status === 200) {
      return response?.data;
    } else {
      throw new Error(`Error, ${response?.status}`);
    }
  } catch (error) {
    if (axios?.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError?.response) {
        console.error('Error del API:', axiosError?.response?.status, axiosError?.response?.data);
      } else {
        console.error('Error de red:', axiosError?.message);
      }
      throw error;
    }
  }
};