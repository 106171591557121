import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const uploadFaltas = async (fileBase64: string, token: string): Promise<any> => {
    try {
        const response = await axios.post(`${apiUrl}/api/grupo/faltas/basico`, {
            file: fileBase64
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        if (response.data && response.data.success) {
            return {
                success: true,
                message: response.data.message || "Success creating grupos"
            };
        } else {
            return {
                success: false,
                message: "Response from server did not contain expected 'success' field."
            };
        }
    } catch (error) {
        // Type assertion to handle the error as an Error object
        const message = (error instanceof Error) ? error.message : "An error occurred while sending the file.";
        console.error('Error al enviar el archivo:', message);
        return {
            success: false,
            message: message
        };
    }
};