import axios, { AxiosResponse } from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const getFormadoresLigados = async (token: string): Promise<any> => {
    try {
        const response: AxiosResponse = await axios.get(
            `${apiUrl}/api/lider/formadores`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(`Error, ${response.status}`);
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const axiosError = error;
            if (axiosError.response) {
                console.error('API Error:', axiosError.response.status, axiosError.response.data);
                throw new Error(`API Error: ${axiosError.response.data}`);
            } else {
                console.error('Network Error:', axiosError.message);
                throw new Error(`Network Error: ${axiosError.message}`);
            }
        } else {
            throw error;
        }
    }
};