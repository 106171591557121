import axios, { AxiosResponse } from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const uploadYastas = async (document: any, token: any): Promise<string | null> => {
  try {
    const response: AxiosResponse = await axios.post(
      `${apiUrl}/api/colaborador/yastas/upload-excel`,
      document,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    const responseData: any = {
      success: true,
      data: response.data
    };
    return responseData;
  } catch (error) {
    console.error('Error al subir el archivo.');

    const errorResponse: any = {
      success: false,
      message: 'Error al subir el archivo.'
    };
    return errorResponse;
  }
};
