import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const marcarNotificacionesLeidas = async (token: string): Promise<any> => {
  try {
    const response = await axios.put(
      `${apiUrl}/api/notificaciones/marcar-leido`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error al marcar notificaciones como leídas:', error);
    throw error;
  }
};