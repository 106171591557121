import React from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbProps } from '../../types/types'; // Asegúrate de que la ruta sea correcta
import styles from './breadCrumb.module.css';

const BreadCrumb: React.FC<BreadcrumbProps> = ({ crumbs }) => {
    return (
        <div className={styles["breadcrumb"]}>
            {crumbs.map((crumb, index) => {
                const isLast = index === crumbs.length - 1;
                return (
                    <>
                        <span className={`${styles.crumb} ${isLast ? styles.last : ''}`}>
                            {!isLast ? (
                                <Link to={crumb.path}>
                                    {crumb.title}
                                </Link>
                            ) : (
                                crumb.title
                            )}
                        </span>
                        <span>
                            {!isLast && ' > '}
                        </span>
                    </>
                );
            })}
        </div>
    );
};

export default BreadCrumb;