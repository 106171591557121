import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getSubdireccionYastas } from '../../services/getSubireccionYastas';
import './editDataSelector.css';

function EditDataSelectorHoteles() {
  const [os, setOs] = useState<string>('');
  const [options, setOptions] = useState<any>([]);
  const [selectedValueString, setSelectedValueString] = useState<any>(null);
  const [selectedValue, setSelectedValue] = useState<any>(null);

  const handleSelectChange = (selected: any) => {
    setSelectedValueString(selected.value);
    setSelectedValue(selected);
  };

  const handleInputChange = (inputValue: any) => {
    const osFilter = '&os=' + inputValue;
    setOs(osFilter);
  };

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (token) {
      getSubdireccionYastas(token, os)
        .then((result) => {
          const rawSedes = result.data.subdireccion_yastas;
          const opciones = Object.entries(rawSedes).map(([key, value]: any) => ({
            value: value.region_yastas,
            label: value.region_yastas
          }));
          setOptions(opciones);
        })
        .catch((error) => {
          console.error('Error al obtener sedes', error);
        });
    }
  }, [os]);

  return (
    <>
      <Select
        isDisabled={true}
        value={selectedValue}
        onChange={handleSelectChange}
        options={options}
        placeholder="Seleccionar hotel"
        isSearchable={true}
        onInputChange={handleInputChange}
      />
    </>
  );
}

export default EditDataSelectorHoteles;
