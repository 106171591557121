import React, { useState, useEffect } from 'react';
import Upload from '../assets/upload-logo.png';
import Filter from '../assets/filter-logo.png';
import LeftArrow from '../assets/arrow-left.png';
import RightArrow from '../assets/arrow-right.png';
import SuccessLogo from '../assets/success-logo.png';
import Quit from '../assets/quit.png';
import ErrorLogo from '../assets/error-logo.png';
import { getColaboradoresYastas } from '../services/getColaboradoresYastas';
import UpdateFormadoresModal from '../components/updateFormadoresModal/updateFormadoresModal';
import UserFallback from '../assets/user-fallback-black.svg';
import Download from '../assets/download-logo.png';
import ThreeDots from '../assets/three-dots.svg';
import { Formador } from '../types/types';

function LideresYFormadores() {
  const [colaboradoresYastas, setColaboradoresYastas] = useState<any>(null);
  const [updateFormadoresResponse, setUpdateYastasResponse] = useState<boolean | null>(null);
  const [updateFormadoresModal, setUpdateYastasModal] = useState<boolean>(false);
  const [rowsLimit, setRowsLimit] = useState<number | ''>(10);
  const [eventoFilter, setEventoFilter] = useState<string>('');
  const [paginationInfo, setPaginationInfo] = useState<any>(null);
  const [pagination, setPagination] = useState<number>(1);
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const isUpdateYastasModal = (openModal: boolean) => {
    setUpdateYastasModal(openModal);
  };

  //   const isfiltersModal = (openFiltersModal: boolean) => {
  //     setFiltersModal(openFiltersModal);
  //   };

  //   const isEventoFilter = (eventoFilter: string) => {
  //     setEventoFilter(eventoFilter);
  //   };

  const isPostSuccess = (postResponse: boolean) => {
    setUpdateYastasResponse(postResponse);
  };

  const isLoadingAlert = (loadAlert: boolean) => {
    setLoadingData(loadAlert);
  };

  const closeAlert = () => {
    setUpdateYastasResponse(null);
  };

  const closeLoadingAlert = () => {
    setLoadingData(false);
  };

  const handleRowsLimit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue: number | '' = e.target.value ? parseInt(e.target.value, 10) : '';
    setRowsLimit(newValue);
  };

    //paginacion

    const [formadores, setFormadores] = useState<Formador[]>([]);
    // Estado para paginación
    const [currentPage, setCurrentPage] = useState(1);
    const [formadoresPerPage, setFormadoresPerPage] = useState(9);
  
    // Calcula los índices de los formadores para la paginación
    const indexOfLastFormador = currentPage * formadoresPerPage;
    const indexOfFirstFormador = indexOfLastFormador - formadoresPerPage;
    const currentFormadores = formadores.slice(indexOfFirstFormador, indexOfLastFormador);
  
    // Manejadores para cambiar de página
    const nextPage = () => {
      setCurrentPage(currentPage + 1);
    };
  
    const previousPage = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };
    //paginacion
  
    //filtrar
    const totalFormadores = formadores.length;
    const totalPages = Math.ceil(totalFormadores / formadoresPerPage);
    const isLastPage = currentPage === totalPages;
    const formadoresLeftToShow = totalFormadores - (currentPage - 1) * formadoresPerPage;
    const shouldShowFillerItems = isLastPage && formadoresLeftToShow < formadoresPerPage;
    const fillerItemsNeeded = shouldShowFillerItems ? formadoresPerPage - formadoresLeftToShow : 0;
   
    // filtrar

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    const apiCall = () => {
      if (token) {
        getColaboradoresYastas(token, rowsLimit, pagination, eventoFilter)
          .then((result) => {
            setColaboradoresYastas(result.data.colaboradores);
            setPaginationInfo(result.data.pagination);
          })
          .catch((error) => {
            console.error('Error al obtener usuario', error);
          });
      }
    };
    apiCall();
    if (updateFormadoresResponse === true) {
      apiCall();
      setLoadingData(false);
      setTimeout(() => {
        setUpdateYastasResponse(null);
      }, 5000);
    }
    if (updateFormadoresResponse === false) {
      setLoadingData(false);
      setTimeout(() => {
        setUpdateYastasResponse(null);
      }, 5000);
    }
  }, [rowsLimit, eventoFilter, pagination, updateFormadoresResponse]);

  return (
    <div className="container colaboradores">
      <div className="flexRowSpaceBetween">
        <div className="flexRowCenter">
          <img src={UserFallback} height={48} width={48} />
          <div className="flexColumnLeft">
            <p className="pBoldBlack">Nombre</p>
            <p className="pBoldBlue">Cargo</p>
          </div>
        </div>
        <div className="flexRowCenter">
          <div className="flexRowGap14">
            <span className="spanBoldBlack">Dirección:</span>
            <p>Centro</p>
          </div>
        </div>
        <div className="flexRowGrey" onClick={() => setUpdateYastasModal(true)}>
          <div className="imgPointer">
            <img src={Upload} alt="upload-logo" />
            <p>AGREGAR FORMADOR</p>
          </div>
          <div className="imgPointer">
            {' '}
            <img src={Download} alt="upload-logo" />
            <p>DESCARGAR</p>
          </div>
        </div>
        {updateFormadoresModal && (
          <UpdateFormadoresModal
            isUpdateFormadoresModal={isUpdateYastasModal}
            isPostSuccess={isPostSuccess}
            isLoadingAlert={isLoadingAlert}
          />
        )}
      </div>
      {loadingData === true && (
        <div className="update-file-response-container">
          <div className="update-file-message loading-data">
            <img src={SuccessLogo} alt="success-logo" />
            <p>Cargando Datos...</p>
            <img src={Quit} alt="quit-logo" onClick={closeLoadingAlert} className="close-alert" />
          </div>
        </div>
      )}
      {updateFormadoresResponse === true && (
        <div className="update-file-response-container">
          <div className="update-file-message update-file-success">
            <img src={SuccessLogo} alt="success-logo" />
            <p>La base de datos se actualizó satisfactoriamente</p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}
      {updateFormadoresResponse === false && (
        <div className="update-file-response-container">
          <div className="update-file-message update-file-error">
            <img src={ErrorLogo} alt="error-logo" />
            <p>
              Hubo un problema con el sistema y la base de datos no se actualizó, por favor,
              inténtalo de nuevo
            </p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}
      
      <div className="grid-container scrollable-container">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((_, index) => (
          <div key={index} className="card">
            <div className="absolute-right">
              <img src={ThreeDots} height={22} width={22} />
            </div>
            <div className="flexRowCenter">
              <img src={UserFallback} height={48} width={48} />
              <div className="flexColumnLeft">
                <p className="pBoldBlack">Nombre</p>
              </div>
            </div>
            <div className="flex-row-space-between-gap">
              <span>Dirección</span>
              <button className="card-button">AGENDA</button>
            </div>
          </div>
        ))}
      </div>
      
      <div className="pagination-container">
        <div className="row-numbers">
          <p>Filas por página:</p>
          <select value={rowsLimit} onChange={handleRowsLimit}>
            <option value={9}>9</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
          </select>
        </div>
        <div className="pagination">
          <p>
            {paginationInfo === null
              ? 'No pagination'
              : paginationInfo.page * paginationInfo.limit -
                (paginationInfo.limit - 1) +
                ' - ' +
                (paginationInfo.page * paginationInfo.limit <= paginationInfo.total
                  ? paginationInfo.page * paginationInfo.limit
                  : paginationInfo.total) +
                ' de ' +
                paginationInfo.total}
          </p>
          <div className="pagination-arrow">
            <img src={LeftArrow} alt="left=arrow" onClick={previousPage} />
            <img src={RightArrow} alt="right-arrow" onClick={nextPage} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LideresYFormadores;
