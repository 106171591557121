import React, { useState, useEffect } from 'react';
import { getNotificaciones } from '../../services/getNotificaciones';
import quitIcon from '../../assets/quit.png';
import iconMensaje from '../../assets/icon-mensaje.png';
import iconCorreo from '../../assets/icon-correo.png';
import '../../components/notificacionHeader/NotificationModal.css';

interface Notification {
  id: number;
  type: string;
  title: string;
  text: string;
  date: string;
  read?: boolean;
}

interface NotificationModalProps {
  onClose: () => void;
  token: string;
  isOpen: boolean;
}

const NotificationModal: React.FC<NotificationModalProps> = ({ onClose, token, isOpen }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  
  useEffect(() => {
    const fetchNotificaciones = async () => {
      try {
        const response = await getNotificaciones(token);
        const notificaciones = response.notificaciones;
        if (Array.isArray(notificaciones)) {
          setNotifications(notificaciones.map(notificacion => ({
            id: notificacion.id,
            type: 'message',
            title: notificacion.title,
            text: notificacion.notificacion,
            date: new Date(notificacion.createdAt).toLocaleDateString('es-Es'),
            read: notificacion.leida,
          })));
        } else {
          console.error('Expected an array for notifications, but received:', response);
          setNotifications([]);
        }
      } catch (error) {
        console.error('Error fetching notifications:', error);
        setNotifications([]);
      }
    };

    fetchNotificaciones();
  }, [token]);


  const markAsRead = (id: number) => {
    setNotifications(current =>
      current.map(notification => notification.id === id ? { ...notification, read: true } : notification)
    );
  };

  const unreadNotificationsCount = notifications.filter(notification => !notification.read).length;

  const getIconPath = (type: string) => {
    switch (type) {
        case 'message':
            return iconMensaje;
        case 'email':
            return iconCorreo;
        default:
            return '';
    }
};
  return (
    <>
      {isOpen && <div className="modalOverlay" onClick={onClose}></div>}
      <div className={`modal ${isOpen ? 'modal-enter' : ''}`}>
        <div className="modalHeader">
          <span>Notificaciones</span>
          <button className="closeButton" onClick={onClose}>
            <img src={quitIcon} alt="Cerrar" />
          </button>
        </div>
        <div className="conteoInfo">
          <span>Tienes {unreadNotificationsCount} mensajes sin leer</span>
        </div>
        <div className="divider "></div>
        <div className="modalContent scrollable">
          {notifications.map(notification => (
            <div key={notification.id} className={`message ${notification.read ? '' : 'messageUnread'}`} onClick={() => markAsRead(notification.id)}>
              <img src={getIconPath(notification.type)} alt={`Icono de ${notification.type}`} className="icon" />
              <div className="messageInfo">
                <p>{notification.title}</p>
                <p>{notification.text}</p>
                <span>Fecha: {notification.date}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default NotificationModal;
