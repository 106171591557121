import axios, { AxiosResponse, AxiosError } from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const downloadGrupos = async (
  token: string,
  fecha: string,
  end: string,
  tipo_curso: string
): Promise<any> => {
  try {
    let response: null | AxiosResponse = null;

    if (tipo_curso == 'avanzado') {
      response = await axios.get(
        `${apiUrl}/api/grupo/download/avanzado/?fecha_inicio=${fecha}&fecha_fin=${end}&tipo_curso=${tipo_curso}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } else {
      response = await axios.get(
        `${apiUrl}/api/grupo/download?fecha_inicio=${fecha}&fecha_fin=${end}&tipo_curso=${tipo_curso}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    }

    if (response?.status === 200) {
      return response?.data;
    } else {
      throw new Error(`Error, ${response?.status}`);
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError?.response) {
        console.error('Error del API:', axiosError?.response?.status, axiosError?.response?.data);
      } else {
        console.error('Error de red:', axiosError?.message);
      }
      throw error;
    }
  }
};
