import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';
import Quit from '../../assets/quit.png';
import { resetPassword } from '../../services/resetPassword';
import '../colabsFilterModal/colabsFilterModal.css';

interface ChangePasswordModalProps {
    isOpen: boolean;
    onClose: () => void;
    token: string;
}

function ChangePasswordModal({ isOpen, onClose, token }: ChangePasswordModalProps) {
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [successMessageOpen, setSuccessMessageOpen] = useState(false);

    const handleChangePassword = async () => {
        if (password) {
            try {
                const response = await resetPassword(token, password);
                console.log('Cambio de contraseña exitoso:', response);
                setSuccessMessageOpen(true);
            } catch (error) {
                console.error('Error al cambiar la contraseña:', error);
            }
        }
    };

    const handleCloseSuccessMessage = () => {
        setSuccessMessageOpen(false);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <>
            <div className="colabs-filter-modal">
                <div className="colabs-filter-container-trackAnual">
                    <div className="colabs-filter-header-modal">
                        <p>Cambiar Contraseña</p>
                        <img src={Quit} alt="Cerrar" onClick={onClose} />
                    </div>
                    <div>
                        <p style={{ marginTop: '0px' }}>g-dircapacitacion@compartamos.com</p>
                    </div>
                    <TextField
                        label="Nueva Contraseña"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                    <IconButton onClick={() => setPassword('')} edge="end">
                                        <CloseIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        style={{ marginBottom: '20px' }}
                    />
                    <div className="filter-buttons-modal">
                        <button className="cancel-button-modal" onClick={onClose}>Cancelar</button>
                        <button className="accept-button-modal" onClick={handleChangePassword}>Cambiar Contraseña</button>
                    </div>
                </div>
            </div>
            <Snackbar
                open={successMessageOpen}
                autoHideDuration={3000}
                onClose={handleCloseSuccessMessage}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <MuiAlert elevation={6} variant="filled" onClose={handleCloseSuccessMessage} severity="success">
                    Contraseña cambiada exitosamente.
                </MuiAlert>
            </Snackbar>
        </>
    );
}

export default ChangePasswordModal;