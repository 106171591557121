import { useState, useEffect } from 'react';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

interface UserData {
  id: number;
  name: string;
  email: string;
  role: 'admin' | 'formador' | 'lider' | 'gerente';
}

const useUser = () => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const token = sessionStorage.getItem('token');

    if (token) {
      const fetchUserData = async () => {
        try {
          const response = await axios.get(`${apiUrl}/api/user`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          const fetchedUserData: UserData = response.data.user;
          setUserData(fetchedUserData);
        } catch (error) {
          console.error('Error fetching user data:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchUserData();
    }
  }, []);

  return { userData, loading };
};

export default useUser;
