import React, { useState } from 'react';
import '../colabsFilterModal/colabsFilterModal.css';
import Quit from '../../assets/quit.png';
import 'react-datepicker/dist/react-datepicker.css';
import { puestoOptions } from '../../constants/ColabsFilterOptions';
import {
  asistenciaOptions,
  modalidadCursoOptions,
  regiosYastasOptions
} from '../../constants/GroupsFilterOptions';
import { filterOptions } from '../../constants/filterOptionsDict';

interface CheckboxState {
  [key: string]: boolean;
}

function GroupsFilterModal({ isGroupsFiltersModal, isFiltering, filterString }: any) {
  const [puestoCheckboxState, setPuestoCheckboxState] = useState<CheckboxState>(
    Object.fromEntries(puestoOptions?.map((option: string) => [option, filterString?.toString()?.match(option)]))
  );
  const [asistenciaCheckboxState, setAsistenciaCheckboxState] = useState<CheckboxState>(
    Object.fromEntries(asistenciaOptions?.map((option: string) => [option, filterString?.toString()?.match(option)]))
  );
  const [modalidadCheckboxState, setModalidadCheckboxState] = useState<CheckboxState>(
    Object.fromEntries(modalidadCursoOptions?.map((option: string) => [option, filterString?.toString()?.match(option)]))
  );
  const [regionCheckboxState, setRegionCheckboxState] = useState<CheckboxState>(
    Object.fromEntries(regiosYastasOptions?.map((option: string) => [option, filterString?.toString()?.match(option)]))
  );

  const handlePuestoCheckboxClick = (name: string, checked: boolean) => {
    setPuestoCheckboxState((prevCheckboxState) => ({
      ...prevCheckboxState,
      [name]: checked
    }));

    if (name === 'selectAll') {
      const updatedCheckboxState: CheckboxState = {};
      puestoOptions.forEach((option) => {
        updatedCheckboxState[option] = checked;
      });

      setPuestoCheckboxState(updatedCheckboxState);
    } else {
      const allCheckboxesChecked = puestoOptions.every((option) => puestoCheckboxState[option]);

      setPuestoCheckboxState((prevCheckboxState) => ({
        ...prevCheckboxState,
        selectAll: allCheckboxesChecked
      }));
    }
  };

  const handleAsistenciaCheckboxClick = (name: string, checked: boolean) => {
    setAsistenciaCheckboxState((prevCheckboxState) => ({
      ...prevCheckboxState,
      [name]: checked
    }));

    if (name === 'selectAll') {
      const updatedCheckboxState: CheckboxState = {};
      asistenciaOptions.forEach((option) => {
        updatedCheckboxState[option] = checked;
      });

      setAsistenciaCheckboxState(updatedCheckboxState);
    } else {
      const allCheckboxesChecked = asistenciaOptions.every(
        (option) => asistenciaCheckboxState[option]
      );

      setAsistenciaCheckboxState((prevCheckboxState) => ({
        ...prevCheckboxState,
        selectAll: allCheckboxesChecked
      }));
    }
  };

  const handleModalidadCheckboxClick = (name: string, checked: boolean) => {
    setModalidadCheckboxState((prevCheckboxState) => ({
      ...prevCheckboxState,
      [name]: checked
    }));

    if (name === 'selectAll') {
      const updatedCheckboxState: CheckboxState = {};
      modalidadCursoOptions.forEach((option) => {
        updatedCheckboxState[option] = checked;
      });

      setModalidadCheckboxState(updatedCheckboxState);
    } else {
      const allCheckboxesChecked = modalidadCursoOptions.every(
        (option) => modalidadCheckboxState[option]
      );

      setModalidadCheckboxState((prevCheckboxState) => ({
        ...prevCheckboxState,
        selectAll: allCheckboxesChecked
      }));
    }
  };

  const handleRegionYASTASCheckboxClick = (name: string, checked: boolean) => {
    setRegionCheckboxState((prevCheckboxState) => ({
      ...prevCheckboxState,
      [name]: checked
    }));

    if (name === 'selectAll') {
      const updatedCheckboxState: CheckboxState = {};
      regiosYastasOptions.forEach((option) => {
        updatedCheckboxState[option] = checked;
      });

      setRegionCheckboxState(updatedCheckboxState);
    } else {
      const allCheckboxesChecked = regiosYastasOptions.every(
        (option) => regionCheckboxState[option]
      );

      setRegionCheckboxState((prevCheckboxState) => ({
        ...prevCheckboxState,
        selectAll: allCheckboxesChecked
      }));
    }
  };

  const changeModalState = () => {
    isGroupsFiltersModal(false);
  };

  const sendEventoFilter = () => {
    isFiltering(createFilterString());
    changeModalState();
  };

  const cleanFilters = () => {
    isFiltering('');
    changeModalState();
  };

  const getDictionary = (key: string) => {
    return filterOptions[key];
  };

  const createFilterString = () => {
    {
      const puestoItems = Object.keys(puestoCheckboxState).filter((key) => puestoCheckboxState[key]);
      const puestoFilter = puestoItems.map((item) => {
        return '&puesto[]=' + item;
      });

      const asistenciaItems = Object.keys(asistenciaCheckboxState).filter(
        (key) => asistenciaCheckboxState[key]
      );
      const asistenciaFilter = asistenciaItems.map((item) => {
        return '&asistencia[]=' + item;
      });

      const modalidadItems = Object.keys(modalidadCheckboxState).filter(
        (key) => modalidadCheckboxState[key]
      );
      const modalidadFilter = modalidadItems.map((item) => {
        return '&modalidad[]=' + item;
      });

      const regionYastasItems = Object.keys(regionCheckboxState).filter(
        (key) => regionCheckboxState[key]
      );
      const regionYastasFilter = regionYastasItems.map((item) => {
        return '&region_yastas[]=' + item;
      });

      const asistenciaToString = asistenciaFilter.toString();
      const puestoToString = puestoFilter.toString();
      const modalidadToString = modalidadFilter.toString();
      const regionYastasToString = regionYastasFilter.toString();

      const filterConcat =
        asistenciaToString +
        puestoToString +
        modalidadToString +
        regionYastasToString;

      return filterConcat.replace(/,/g, '');
    }
  }

  return (
    <div className="colabs-filter-modal">
      <div className="colabs-filter-container">
        <div className="colabs-filter-header">
          <p>Filtros</p>
          <img src={Quit} alt="quit-logo" onClick={changeModalState} />
        </div>
        {/* <div>
          <p className="colabs-filter-option">Grupos</p>
          <div className="checkbox-container">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  name="selectAll"
                  checked={
                    gruposOptions.length > 0 &&
                    gruposOptions.every((option) => grupoCheckboxState[option])
                  }
                  onChange={(e) => handleGrupoCheckboxClick('selectAll', e.target.checked)}
                />
                Agregar todos
              </label>
            </div>
            {gruposOptions.map((option) => (
              <div key={option} className="checkbox">
                <label
                  style={{
                    textTransform: 'capitalize'
                  }}
                >
                  <input
                    type="checkbox"
                    name={option}
                    checked={grupoCheckboxState[option] || false}
                    onChange={(e) => handleGrupoCheckboxClick(option, e.target.checked)}
                  />
                  {option.toLowerCase()}
                </label>
              </div>
            ))}
          </div>
        </div> */}
        <div>
          <p className="colabs-filter-option">Puesto</p>
          <div className="checkbox-container">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  name="selectAll"
                  checked={
                    puestoOptions.length > 0 &&
                    puestoOptions.every((option) => puestoCheckboxState[option])
                  }
                  onChange={(e) => handlePuestoCheckboxClick('selectAll', e.target.checked)}
                />
                Agregar todos
              </label>
            </div>
            {puestoOptions.map((option) => (
              <div key={option} className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name={option}
                    checked={puestoCheckboxState[option] || false}
                    onChange={(e) => handlePuestoCheckboxClick(option, e.target.checked)}
                  />
                  {getDictionary(option)}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div>
          <p className="colabs-filter-option">Asistencia</p>
          <div className="checkbox-container">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  name="selectAll"
                  checked={
                    asistenciaOptions.length > 0 &&
                    asistenciaOptions.every((option) => asistenciaCheckboxState[option])
                  }
                  onChange={(e) => handleAsistenciaCheckboxClick('selectAll', e.target.checked)}
                />
                Agregar ambos
              </label>
            </div>
            {asistenciaOptions.map((option) => (
              <div key={option} className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name={option}
                    checked={asistenciaCheckboxState[option] || false}
                    onChange={(e) => handleAsistenciaCheckboxClick(option, e.target.checked)}
                  />
                  {option === 'true' ? 'Si' : 'No'}
                </label>
              </div>
            ))}
          </div>
        </div>
        {/* <div>
          <p className="colabs-filter-option">Ruta de aprendizaje</p>
          <div className="checkbox-container">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  name="selectAll"
                  checked={
                    rutaAprendizajeOptions.length > 0 &&
                    rutaAprendizajeOptions.every((option) => rutaAprendizajeCheckboxState[option])
                  }
                  onChange={(e) => handleRutaCheckboxClick('selectAll', e.target.checked)}
                />
                Agregar todos
              </label>
            </div>
            {rutaAprendizajeOptions.map((option) => (
              <div key={option} className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name={option}
                    checked={rutaAprendizajeCheckboxState[option] || false}
                    onChange={(e) => handleRutaCheckboxClick(option, e.target.checked)}
                  />
                  {getDictionary(option.replace(' BASICO', ''))}
                </label>
              </div>
            ))}
          </div>
        </div> */}
        <div>
          <p className="colabs-filter-option">Modalidad del curso</p>
          <div className="checkbox-container">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  name="selectAll"
                  checked={
                    modalidadCursoOptions.length > 0 &&
                    modalidadCursoOptions.every((option) => modalidadCheckboxState[option])
                  }
                  onChange={(e) => handleModalidadCheckboxClick('selectAll', e.target.checked)}
                />
                Agregar ambos
              </label>
            </div>
            {modalidadCursoOptions.map((option) => (
              <div key={option} className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name={option}
                    checked={modalidadCheckboxState[option] || false}
                    onChange={(e) => handleModalidadCheckboxClick(option, e.target.checked)}
                  />
                  {getDictionary(option)}
                </label>
              </div>
            ))}
          </div>
        </div>
        {/* <div className="fecha-contratacion">
          <p className="colabs-filter-option">Fecha inicio de curso</p>
          <div className="date-picker-container">
        
            <DatePicker
              selected={startDate}
              onChange={onChange}
              showYearDropdown
              dateFormatCalendar="MMMM"
              dateFormat="yyyy-MM-dd"
              yearDropdownItemNumber={50}
              scrollableYearDropdown
              //startDate={startDate}
              //endDate={endDate}
              //selectsRange
            />
          </div>
        </div> */}
        <div>
          <p className="colabs-filter-option">Región YASTAS</p>
          <div className="checkbox-container">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  name="selectAll"
                  checked={
                    regiosYastasOptions.length > 0 &&
                    regiosYastasOptions.every((option) => regionCheckboxState[option])
                  }
                  onChange={(e) => handleRegionYASTASCheckboxClick('selectAll', e.target.checked)}
                />
                Agregar todos
              </label>
            </div>
            {regiosYastasOptions.map((option) => (
              <div key={option} className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name={option}
                    checked={regionCheckboxState[option] || false}
                    onChange={(e) => handleRegionYASTASCheckboxClick(option, e.target.checked)}
                  />
                  {getDictionary(option)}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="upload-file-buttons">
          <button className="cancel-button" onClick={cleanFilters}>
            QUITAR FILTROS
          </button>
          <button className="accept-button" onClick={sendEventoFilter}>
            APLICAR FILTROS
          </button>
        </div>
      </div>
    </div>
  );
}

export default GroupsFilterModal;
