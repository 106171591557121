import axios, { AxiosResponse, AxiosError } from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const envioDeInvitacionesFinal = async (token: string, type?: string): Promise<any> => {
    try {
        const response: AxiosResponse = await axios.post(`${apiUrl}/api/invitaciones/send`,
            {
                tipo_curso: type
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response.status === 200) {
            console.log('Invitaciones enviadas exitosamente.');
            return response.data;
        } else {
            throw new Error(`Error, ${response.status}`);
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError;
            if (axiosError.response) {
                console.error('Error del API:', axiosError.response.status, axiosError.response.data);
            } else {
                console.error('Error de red:', axiosError.message);
            }
            throw error;
        }
    }
};