import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './layouts/header/header';
import Menu from './layouts/menu/menu';
import Employees from './views/Employees';
import Groups from './views/Groups';
import Sedes from './views/Sedes';
import Reports from './views/Reports';
import DetallesCapacitacion from './views/DetallesCapacitacion';
import GenericView from './views/GenericView';
import Login from './views/Login';
import Home from './views/Home';
import Yastas from './views/Yastas';
import LideresYFormadores from './views/LideresyFormadores';
import GestionLideresFormadores from './views/GestionLideresFormadores';
import Formadores from './views/FormadorLider/Formadores';
import Agenda from './views/FormadorLider/Agenda';
import Hoteles from './views/Hoteles';

export type collapsingMenu = (menuCollapsingState: boolean) => void;

function App() {
  const [menuCollapsing, setMenuCollapsing] = useState<boolean>(false);
  const [isUserLoggedIn, setUserLoggedIn] = useState<boolean>(false);

  const collapsingMenu = (menuCollapsingState: boolean) => {
    setMenuCollapsing(menuCollapsingState);
  };

  const userLoggedIn = (userLoggedInState: string) => {
    if (userLoggedInState != null) {
      setUserLoggedIn(true);
    }
  };

  return (
    <Router>
      <div className="App">
        <Header onMenuChange={collapsingMenu} />
        <div className="content-container">
          <Menu menuState={menuCollapsing} isMobile />
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="*" element={isUserLoggedIn ? <Navigate to="/login" /> : null} />
            <Route path="/login" element={<Login userLoggedIn={userLoggedIn} />} />
            <Route path="/home" element={<Home />} />
            <Route path="/colaboradores" element={<Employees />} />
            <Route path="/grupos" element={<GenericView />} />
            <Route path="/grupos/basico" element={<Groups />} />
            <Route path="/grupos/intermedio" element={<Groups />} />
            <Route path="/grupos/avanzado" element={<Groups />} />
            <Route path="/sedes" element={<Sedes />} />
            <Route path="/hoteles" element={<Hoteles />} />
            <Route path="/trackAnual" element={<Reports />} />
            <Route path="/trackAnual/detalledeTrackCapacitacion/:nomina" element={<DetallesCapacitacion />} /> 
            <Route path="/yastas" element={<Yastas />} />
            <Route path="/formadores" element={<Formadores />} />
            <Route path="/formadores/agenda/:id" element={<Agenda />} />
            <Route path="/agenda" element={<Agenda />} />
            <Route path="/lyf" element={<GenericView />} />
            <Route path="/lyf/lyf_dashboard" element={<LideresYFormadores />} />
            <Route path="/lyf/lyf_gestion" element={<GestionLideresFormadores />} />         
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
