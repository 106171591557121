import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import '../colabsFilterModal/colabsFilterModal.css';
import Quit from '../../assets/quit.png';
import { IconButton, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AppState } from '../../types/types';
import { useSelector } from 'react-redux';

interface TrackAnualFilterModalProps {
  isGroupsFiltersModal: (open: boolean) => void;
  onApplyFilters: (filters: {
    nombre?: string | null;
    puesto?: string | null;
    direccion?: string | null;
    subdireccion?: string | null;
    os?: string | null;
    nomina?: string | null;
  }) => void;
  colaboradores: string[];
  puestos: string[];
  direcciones?: string[];
  subdirecciones?: string[];
  oses?: string[];
  nomina?: string[];
}

function TrackAnualFilterModal({
  isGroupsFiltersModal,
  onApplyFilters,
  colaboradores
}: TrackAnualFilterModalProps) {
  const [selectedPuesto, setSelectedPuesto] = useState<string | null>(null);
  const [selectedColaborador, setSelectedColaborador] = useState<string | null>(null);
  const [selectedDireccion, setSelectedDireccion] = useState<string | null>(null);
  const [selectedSubdireccion, setSelectedSubdireccion] = useState<string | null>(null);
  const [selectedOs, setSelectedOs] = useState<string | null>(null);
  const [selectedNomina, setSelectedNomina] = useState<string | null>(null);
  const rolUsuario = useSelector((state: AppState) => state.user.userData?.role);

  const changeModalState = () => isGroupsFiltersModal(false);

  useEffect(() => {
    const savedFilters = localStorage.getItem('trackAnualFilters');
    if (savedFilters) {
      const { nombre, puesto, direccion, subdireccion, os, nomina } = JSON.parse(savedFilters);
      setSelectedColaborador(nombre);
      setSelectedPuesto(puesto);
      setSelectedDireccion(direccion);
      setSelectedSubdireccion(subdireccion);
      setSelectedOs(os);
      setSelectedNomina(nomina);
    }
  }, []);

  const saveFiltersToStorage = (filters: any) => {
    localStorage.setItem('trackAnualFilters', JSON.stringify(filters));
  };


  const sendEventoFilter = () => {
    const filters = {
      nombre: selectedColaborador ?? '',
      puesto: selectedPuesto ?? '',
      direccion: selectedDireccion ?? '',
      subdireccion: selectedSubdireccion ?? '',
      os: selectedOs ?? '',
      nomina: selectedNomina ?? '',
    };
    onApplyFilters(filters);
    saveFiltersToStorage(filters);
    changeModalState();
  };

  const cleanFilters = () => {
    setSelectedPuesto(null);
    setSelectedColaborador(null);
    setSelectedDireccion(null);
    setSelectedSubdireccion(null);
    setSelectedOs(null);
    onApplyFilters({ nombre: null, puesto: null, direccion: null, subdireccion: null, os: null });

    localStorage.removeItem('trackAnualFilters');
    changeModalState();
  };

  return (
    <div className="colabs-filter-modal">
      <div className="colabs-filter-container-trackAnual">
        <div className="colabs-filter-header-modal">
          <p>Filtros</p>
          <img src={Quit} alt="Cerrar" onClick={changeModalState} />
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', gap: '4%' }}>
          <Autocomplete
            value={selectedColaborador}
            onChange={(event, newValue) => {
              setSelectedColaborador((newValue || '').toUpperCase());
            }}
            style={{ width: '41%' }}
            inputValue={selectedColaborador ?? ''}
            onInputChange={(event, newInputValue) => {
              setSelectedColaborador(newInputValue.toUpperCase());
            }}
            options={colaboradores.map((colaborador) => colaborador.toUpperCase())}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Colaborador"
                InputProps={{
                  ...params.InputProps,
                  style: { textTransform: 'uppercase' },
                }}
                style={{ width: '100%' }}
              />
            )}
            freeSolo
          />
          <TextField
            label="Nómina"
            value={selectedNomina ?? ''}
            onChange={(event) => setSelectedNomina(event.target.value.toUpperCase())}
            style={{ width: '40%' }}
            InputProps={{
              endAdornment: (
                selectedNomina ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setSelectedNomina(null)}
                      edge="end"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null
              ),
            }}
          />
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', gap: '4%', marginTop: '20px' }}>
          <TextField
            label="Puesto"
            value={selectedPuesto ?? ''}
            onChange={(event) => setSelectedPuesto(event.target.value.toUpperCase())}
            style={{ width: rolUsuario === 'gerente' ? '85%' : '41%' }} // Ajusta el ancho según el rol
            InputProps={{
              endAdornment: (
                selectedPuesto ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setSelectedPuesto(null)}
                      edge="end"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null
              ),
            }}
          />
          {rolUsuario !== 'gerente' && ( // Solo muestra este campo si el usuario no es un gerente
            <TextField
              label="OS"
              value={selectedOs ?? ''}
              onChange={(event) => setSelectedOs(event.target.value.toUpperCase())}
              style={{ width: '40%' }}
              InputProps={{
                endAdornment: (
                  selectedOs ? (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setSelectedOs(null)}
                        edge="end"
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : null
                ),
              }}
            />
          )}
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', gap: '4%', marginTop: '20px' }}>
          <TextField
            label="Dirección"
            value={selectedDireccion ?? ''}
            onChange={(event) => setSelectedDireccion(event.target.value.toUpperCase())}
            style={{ width: '41%' }}
            InputProps={{
              endAdornment: (
                selectedDireccion ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setSelectedDireccion(null)}
                      edge="end"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null
              ),
            }}
          />
          <TextField
            label="Subdirección"
            value={selectedSubdireccion ?? ''}
            onChange={(event) => setSelectedSubdireccion(event.target.value.toUpperCase())}
            style={{ width: '40%' }}
            InputProps={{
              endAdornment: (
                selectedSubdireccion ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setSelectedSubdireccion(null)}
                      edge="end"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null
              ),
            }}
          />
        </div>
        <div className="filter-buttons-modal">
          <button className="cancel-button-modal" onClick={cleanFilters}>
            CANCELAR
          </button>
          <button className="accept-button-modal" onClick={sendEventoFilter}>
            APLICAR FILTROS
          </button>
        </div>
      </div>
    </div>
  );
}

export default TrackAnualFilterModal;