import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getGrupos } from '../../services/getGrupos';
import './editDataSelector.css';

function EditDataSelectorGrupos({ groupsDate }: any) {
  const [os, setOs] = useState<string>('');
  const [options, setOptions] = useState<any>([]);
  const [selectedValueString, setSelectedValueString] = useState<any>(null);
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const rowsLimit = 50;
  const pagination = 1;
  const filter = '';
  const selectedDate = groupsDate;

  const handleSelectChange = (selected: any) => {
    setSelectedValueString(selected.value);
    setSelectedValue(selected);
  };

  const handleInputChange = (inputValue: any) => {
    const osFilter = '&os=' + inputValue;
    setOs(osFilter);
  };

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (token) {
      getGrupos(token, rowsLimit, pagination, filter, selectedDate)
        .then((result) => {
          const rawSedes = result.data;
          const opciones = Object.entries(rawSedes).map(([key, value]: any) => ({
            value: value.direccion,
            label: value.direccion
          }));
          setOptions(opciones);
        })
        .catch((error) => {
          console.error('Error al obtener sedes', error);
        });
    }
  }, [os]);

  return (
    <>
      <Select
        isDisabled={true}
        value={selectedValue}
        onChange={handleSelectChange}
        options={options}
        placeholder="Seleccionar grupo"
        isSearchable={true}
        onInputChange={handleInputChange}
      />
    </>
  );
}

export default EditDataSelectorGrupos;
