import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const uploadFaltasConNiveles = async (fileBase64: string, tipoCurso: string, token: string) => {
    try {
        const response = await axios.post(`${apiUrl}/api/grupo/faltas/niveles`, {
            tipo_curso: tipoCurso,
            file: fileBase64
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        if (response.data && response.data.success) {
            return {
                success: true,
                message: response.data.message || "Success creating grupos with levels"
            };
        } else {
            return {
                success: false,
                message: "Response from server did not contain expected 'success' field."
            };
        }
    } catch (error) {
        const message = (error instanceof Error) ? error.message : "An error occurred while sending the file.";
        console.error('Error al enviar el archivo:', message);
        return {
            success: false,
            message: message
        };
    }
};

export const uploadFaltasIntermedio = async (fileBase64: string, tipoCurso: string, token: string) => {
    try {
        const response = await axios.post(`${apiUrl}/api/grupo/faltas/intermedio`, {
            tipo_curso: tipoCurso,
            file: fileBase64
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        if (response.data && response.data.success) {
            return {
                success: true,
                message: response.data.message || "Success creating grupos with levels"
            };
        } else {
            return {
                success: false,
                message: "Response from server did not contain expected 'success' field."
            };
        }
    } catch (error) {
        const message = (error instanceof Error) ? error.message : "An error occurred while sending the file.";
        console.error('Error al enviar el archivo:', message);
        return {
            success: false,
            message: message
        };
    }
};

export const uploadFaltasAvanzado = async (fileBase64: string, tipoCurso: string, token: string) => {
    try {
        const response = await axios.post(`${apiUrl}/api/grupo/faltas/avanzado`, {
            tipo_curso: tipoCurso,
            file: fileBase64
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        if (response.data && response.data.success) {
            return {
                success: true,
                message: response.data.message || "Success creating grupos with levels"
            };
        } else {
            return {
                success: false,
                message: "Response from server did not contain expected 'success' field."
            };
        }
    } catch (error) {
        const message = (error instanceof Error) ? error.message : "An error occurred while sending the file.";
        console.error('Error al enviar el archivo:', message);
        return {
            success: false,
            message: message
        };
    }
};