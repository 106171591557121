import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { searchLiderByName } from '../../services/searchLiderByName';
import { getLiderDirecciones } from '../../services/getLiderDirecciones';
import { agregarFormador } from '../../services/agregarFormador';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import '../colabsFilterModal/colabsFilterModal.css';
import Quit from '../../assets/quit.png';
import CircularProgress from '@mui/material/CircularProgress';

interface AgregarFormadorModalProps {
    isOpen: boolean;
    onClose: () => void;
    onFormadorAgregado?: () => void;
}

interface Direccion {
    direccion: string;
    subdireccion: string;
    estado_os: string;
    os: string;
}

interface Formador {
    id: number;
    nombre: string;
    nomina: string;
    puesto: string;
    role: string;
}

const AgregarFormadorModal = ({ isOpen, onClose, onFormadorAgregado }: AgregarFormadorModalProps) => {
    const [direcciones, setDirecciones] = useState<Direccion[]>([]);
    const [subdirecciones, setSubdirecciones] = useState<string[]>([]);
    const [nombreFormador, setNombreFormador] = useState<string>('');
    const [selectedSubdireccion, setSelectedSubdireccion] = useState<string | null>(null);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [formadores, setFormadores] = useState<Formador[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const token = sessionStorage.getItem('token');
        if (token) {
            getLiderDirecciones(token).then(response => {
                setDirecciones(response.direcciones);
                const subs = response.direcciones.map((d: Direccion) => d.subdireccion);  
                const subdirsSet = new Set<string>(subs);
                const uniqueSubs = Array.from(subdirsSet); 
                setSubdirecciones(uniqueSubs);
            }).catch(console.error);
        }
    }, []);
    
    const buscarFormadoresPorNombre = async (nombre: string) => {
        const token = sessionStorage.getItem('token');
        // Usa trim() para eliminar espacios en blanco al principio y al final del nombre
        if (nombre.trim() && token) {
            setIsLoading(true); // Inicia el indicador de carga
            try {
                // Asegúrate de usar nombre.trim() también en la llamada al servicio
                const resultado = await searchLiderByName(token, nombre.trim());
                setFormadores(resultado.formadores);
            } catch (error) {
                console.error('Error al buscar formadores', error);
            } finally {
                setIsLoading(false); // Detiene el indicador de carga
            }
        }
    };

    useEffect(() => {
        if (nombreFormador) buscarFormadoresPorNombre(nombreFormador);
    }, [nombreFormador]);

    const handleAgregar = async () => {
        const token = sessionStorage.getItem('token');
        if (selectedSubdireccion && nombreFormador && token) {
            const direccionSeleccionada = direcciones.find(d => d.subdireccion === selectedSubdireccion);
            if (!direccionSeleccionada) {
                console.error('Dirección no encontrada');
                return;
            }

            const formadorSeleccionado = formadores.find(f => f.nombre === nombreFormador);
            if (!formadorSeleccionado) {
                console.error('Formador no encontrado');
                return;
            }

            const nuevaInfoFormador = {
                nomina: formadorSeleccionado.nomina,
                os: direccionSeleccionada.os,
                subdireccion: selectedSubdireccion,
                estado_os: direccionSeleccionada.estado_os,
            };

            try {
                await agregarFormador(token, nuevaInfoFormador);
                setSnackbarOpen(true);
                if (onFormadorAgregado) onFormadorAgregado(); // Notifica al componente padre
                setTimeout(onClose, 2000); // Cierra el modal después de un breve retardo
            } catch (error) {
                console.error('Error al agregar el formador:', error);
            }
        } else {
            console.error('Datos faltantes para agregar al formador');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="colabs-filter-modal">
            <div className="colabs-filter-container-trackAnual">
                <div className="colabs-filter-header-modal">
                    <p>Agregar Nuevo Formador</p>
                    <img src={Quit} alt="Cerrar" onClick={onClose} />
                </div>
                <Autocomplete
                    options={formadores.map(formador => formador.nombre)}
                    onInputChange={(event, newInputValue) => {
                        setNombreFormador(newInputValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Nombre del Formador"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
                <Autocomplete
                    value={selectedSubdireccion}
                    onChange={(event, newValue) => {
                        setSelectedSubdireccion(newValue);
                    }}
                    options={subdirecciones}
                    renderInput={(params) => <TextField {...params} label="Subdirección" />}
                />
                <div className="filter-buttons-modal">
                    <Button className="cancel-button-modal" onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button className="accept-button-modal" onClick={handleAgregar}>
                        Agregar
                    </Button>
                </div>
            </div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={() => setSnackbarOpen(false)}
                message="Formador actualizado exitosamente"
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                ContentProps={{
                    style: { backgroundColor: 'green' }
                }}
            />
        </div>
    );
};

export default AgregarFormadorModal;