interface KeyToValue {
  [key: string]: string;
}

export const filterOptions: KeyToValue = {
  'AGREGAR TODOS': 'Agregar todos',
  BAJA: 'Baja',
  'CAMBIO DE DATOS': 'Cambio de Datos',
  'CAMBIO DE POSICION': 'Cambio de Posición',
  'CAMBIO DE SUELDO': 'Cambio de Sueldo',
  INGRESO: 'Ingreso',
  PROMOCION: 'Promoción',
  'REACTIVACION DE EMPLEADO': 'Reactivación de Empleado',
  REINGRESO: 'Reingreso',
  SUSPENSION: 'Suspensión',
  ACTUALIZADOS: 'Actualizados',
  NUEVOS: 'Nuevos',
  ACTIVO: 'Activo',
  'ADMINISTRADOR OPERACIONES': 'Administrador de operaciones',
  'ADMINISTRADOR SUCURSAL': 'Administrador de sucursal',
  'ASESOR CI': 'Asesor CI',
  'ASESOR COMERCIAL': 'Asesor comercial',
  'AUXILIAR OPERACIONES': 'Auxiliar de operaciones',
  CAJERO: 'Cajero',
  'CAJERO COMODIN': 'Cajero comodín',
  EJECUTIVO: 'Ejecutivo',
  'GERENTE OS': 'Gerente OS',
  GESTOR: 'Gestor',
  LIDER: 'Líder',
  'PROMOTOR CREDITO GRUPAL': 'Promotor crédito grupal',
  'SUBGERENTE CI': 'Subgerente CI',
  'SUBGERENTE GRUPAL': 'Subgerente grupal',
  CENTRO: 'Centro',
  'CENTRO NORTE': 'Centro Norte',
  METRO: 'Metro',
  NORESTE: 'Noreste',
  OCCIDENTE: 'Occidente',
  PACIFICO: 'Pacífico',
  PENINSULA: 'Península',
  SUR: 'Sur',
  SURESTE: 'Sureste',
  'DIRECCION OPERACIONES': 'Dirección de operaciones',
  'DIRECCION VENTAS CENTRO': 'Dirección de ventas centro',
  'DIRECCION VENTAS METRO': 'Dirección de ventas metro',
  'DIRECCION VENTAS NORTE': 'Dirección de ventas norte',
  'DIRECCION VENTAS NORTE PACIFICO': 'Dirección de ventas norte pacífico',
  'DIRECCION VENTAS SUR': 'Dirección de ventas sur',
  OPERACIONES: 'Operaciones',
  VENTAS: 'Ventas',
  YASTAS: 'YASTAS',
  ADOS: 'ADOS',
  AOS: 'AOS',
  'ASESOR DE NEGOCIO YASTAS': 'Asesor de negocio YASTAS',
  'ESEC SUCURSAL': 'ESEC sucursal',
  'GESTOR DE COBRANZA': 'Gestor de cobranza',
  'LIDER GRUPAL': 'Líder de grupo',
  PRESENCIAL: 'Presencial',
  'EN LINEA': 'En línea',
  'CENTRO ORIENTE': 'Centro Oriente',
  GOLFO: 'Golfo',
  'METRO ORIENTE': 'Metro Oriente',
  NORTE: 'Norte',
  'NORTE ATLANTICO': 'Norte Atlántico',
  'NORTE PACIFICO': 'Norte Pacífico',
  OAXACA: 'Oaxaca'
};
