import axios, { AxiosResponse, AxiosError } from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const actualizarFormador = async (token: string, nominaId: string, subdireccion: string): Promise<any> => {
    try {
        const response: AxiosResponse = await axios.put(`${apiUrl}/api/lider/formadores/update`, {
            nomina: nominaId,
            direccion: subdireccion
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status === 200 || response.status === 204) {
            console.log('Formador actualizado exitosamente.');
            return response.data;
        } else {
            throw new Error(`Error al actualizar formador, ${response.status}`);
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError;
            if (axiosError.response) {
                console.error('Error del API al actualizar formador:', axiosError.response.status, axiosError.response.data);
            } else {
                console.error('Error de red al actualizar formador:', axiosError.message);
            }
            throw error;
        }
    }
};