import React, { useState } from 'react';
import '../styles/styles.css';
import logoGentera from '../assets/logo-COE.png';
import template_banner_left from '../assets/template_banner-left.png';
import template_banner_rigth from '../assets/template_banner-rigth.png';
import show_password from '../assets/show-password.png';
import user from '../assets/user.png';
import lock from '../assets/password.png';
import { authUser } from '../services/loginAuth';
import { decodeToken } from 'react-jwt';

function Login({ userLoggedIn }: any): React.ReactElement {
  const [nomina, setNomina] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorText, setErrorText] = useState<string>('');
  const [nominaError, setNominaError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [authError, setAuthError] = useState<boolean>(false);

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const changingShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    setErrorText('');
    setNominaError(false);
    setPasswordError(false);

    if (!nomina && !password) {
      setErrorText('Favor de ingresar datos');
      setNominaError(true);
      setPasswordError(true);
      return;
    }

    if (!nomina) {
      setErrorText('Ingrese nomina');
      setNominaError(true);
      return;
    }

    if (!password) {
      setErrorText('Ingrese contraseña');
      setPasswordError(true);
      return;
    }

    try {
      const token = await authUser(nomina, password);

      if (token) {
        sessionStorage.setItem('token', token);
        const decodedToken = decodeToken(token);
        localStorage.setItem('user', JSON.stringify(decodedToken));
        userLoggedIn(token);
        window.location.href = '/home';
      }
    } catch (err: any) {
      console.log(err.response.data);

      if (err.response) {
      
          setErrorText('Nómina o contraseña incorrecta');
          setNominaError(true);
          setPasswordError(true);
        } 
      }
  };

  return (
    <div className="login-container">
      <img src={template_banner_left} alt="template-banner" className="left-banner" />
      <img src={template_banner_rigth} alt="template-banner" className="rigth-banner" />
      <div className="login-elements">
        <img src={logoGentera} alt="logoGentera" className="login-logo" />
        <h2 className="login-title">Gestión Automatizada de la Capacitación (GAC)</h2>
        <p className="login-text">
          Por favor ingresa los datos solicitados para poder ingresar a la plataforma.
        </p>
        <div className="login-inputs">
          {errorText && <p className="error-text">{errorText}</p>}
          <div className="login-input">
            <img src={user} alt="user-logo" className="input-logo" />
            <input
              type="text"
              placeholder="Nomina"
              value={nomina}
              onChange={(e) => {
                setNomina(e.target.value);
                setNominaError(false);
                setAuthError(false); // Restablece el estado de error de autenticación
              }}
              className={(nominaError || authError) ? 'error-input' : ''}
            />
          </div>
          <div className="login-input">
            <img src={lock} alt="password-logo" className="input-logo" />
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="Contraseña"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordError(false); // Restablece el estado de error al editar el campo
                setAuthError(false); // Restablece el estado de error de autenticación
              }}
              className={(passwordError || authError) ? 'error-input' : ''}
            />
            <img
              src={show_password}
              alt="showpassword-logo"
              className="show-password"
              onClick={changingShowPassword}
            />
          </div>
          <button onClick={handleLogin} onTouchStart={handleLogin} className="submit-button">
            INICIAR SESIÓN
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
