import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const uploadExcel = async (fileBase64: string, token: string): Promise<any> => {
    try {
        const response = await axios.post(`${apiUrl}/api/concentrado/upload-excel`, {
            file: fileBase64
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        if (response.data && response.data.success) {
            return {
                success: true,
                message: response.data.message || "Éxito al cargar el archivo Excel."
            };
        } else {
            return {
                success: false,
                message: "La respuesta del servidor no contiene el campo 'success' esperado."
            };
        }
    } catch (error) {
        const message = (error instanceof Error) ? error.message : "Ocurrió un error al enviar el archivo.";
        console.error('Error al enviar el archivo Excel:', message);
        return {
            success: false,
            message: message
        };
    }
};