import axios, { AxiosResponse, AxiosError } from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const getSedesTabla = async (
  token: string,
  rowsLimit: number | '',
  pagination: number,
  os: string
): Promise<any> => {
  try {
    const response: AxiosResponse = await axios.get(
      `${apiUrl}/api/sede/?limit=${rowsLimit}&page=${pagination}${os}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Error, ${response.status}`);
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        console.error('Error del API:', axiosError.response.status, axiosError.response.data);
      } else {
        console.error('Error de red:', axiosError.message);
      }
      throw error;
    }
  }
};
