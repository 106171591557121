import React, { useEffect, useState } from 'react';
import './colabsFilterModal.css';
import Quit from '../../assets/quit.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  eventoOptions,
  actualizacionOptions,
  estadoOptions,
  puestoOptions,
  direccionOptions,
  negocioOptions
} from '../../constants/ColabsFilterOptions';
import { filterOptions } from '../../constants/filterOptionsDict';
import { format } from 'date-fns';

interface CheckboxState {
  [key: string]: boolean;
}

function ColabsFilterModal({ isfiltersModal, isEventoFilter }: any) {
  const [filtro, setFiltro] = useState<any>('');
  const [selectedDate, setSelectedDate] = useState<any>('');
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [eventoCheckboxState, setEventoCheckboxState] = useState<CheckboxState>({});
  const [actualizacionCheckboxState, setActualizacionCheckboxState] = useState<CheckboxState>({});
  const [estadoCheckboxState, setEstadoCheckboxState] = useState<CheckboxState>({});
  const [puestoCheckboxState, setPuestoCheckboxState] = useState<CheckboxState>({});
  const [direccionCheckboxState, setDireccionCheckboxState] = useState<CheckboxState>({});
  const [negocioCheckboxState, setNegocioCheckboxState] = useState<CheckboxState>({});

  const handleEventoCheckboxClick = (name: string, checked: boolean) => {
    setEventoCheckboxState((prevCheckboxState) => ({
      ...prevCheckboxState,
      [name]: checked
    }));
    if (name === 'selectAll') {
      const updatedCheckboxState: CheckboxState = {};
      eventoOptions.forEach((option) => {
        updatedCheckboxState[option] = checked;
      });
      setEventoCheckboxState(updatedCheckboxState);
    } else {
      const allCheckboxesChecked = eventoOptions.every((option) => eventoCheckboxState[option]);
      setEventoCheckboxState((prevCheckboxState) => ({
        ...prevCheckboxState,
        selectAll: allCheckboxesChecked
      }));
    }
  };

  const handleActualizacionCheckboxClick = (name: string, checked: boolean) => {
    setActualizacionCheckboxState((prevCheckboxState) => ({
      ...prevCheckboxState,
      [name]: checked
    }));
    if (name === 'selectAll') {
      const updatedCheckboxState: CheckboxState = {};
      actualizacionOptions.forEach((option) => {
        updatedCheckboxState[option] = checked;
      });
      setActualizacionCheckboxState(updatedCheckboxState);
    } else {
      const allCheckboxesChecked = actualizacionOptions.every(
        (option) => actualizacionCheckboxState[option]
      );
      setActualizacionCheckboxState((prevCheckboxState) => ({
        ...prevCheckboxState,
        selectAll: allCheckboxesChecked
      }));
    }
  };

  const handleEstadoCheckboxClick = (name: string, checked: boolean) => {
    setEstadoCheckboxState((prevCheckboxState) => ({
      ...prevCheckboxState,
      [name]: checked
    }));
    if (name === 'selectAll') {
      const updatedCheckboxState: CheckboxState = {};
      estadoOptions.forEach((option) => {
        updatedCheckboxState[option] = checked;
      });
      setEstadoCheckboxState(updatedCheckboxState);
    } else {
      const allCheckboxesChecked = estadoOptions.every((option) => estadoCheckboxState[option]);
      setEstadoCheckboxState((prevCheckboxState) => ({
        ...prevCheckboxState,
        selectAll: allCheckboxesChecked
      }));
    }
  };

  const handlePuestoCheckboxClick = (name: string, checked: boolean) => {
    setPuestoCheckboxState((prevCheckboxState) => ({
      ...prevCheckboxState,
      [name]: checked
    }));
    if (name === 'selectAll') {
      const updatedCheckboxState: CheckboxState = {};
      puestoOptions.forEach((option) => {
        updatedCheckboxState[option] = checked;
      });
      setPuestoCheckboxState(updatedCheckboxState);
    } else {
      const allCheckboxesChecked = puestoOptions.every((option) => puestoCheckboxState[option]);
      setPuestoCheckboxState((prevCheckboxState) => ({
        ...prevCheckboxState,
        selectAll: allCheckboxesChecked
      }));
    }
  };

  const handleDireccionCheckboxClick = (name: string, checked: boolean) => {
    setDireccionCheckboxState((prevCheckboxState) => ({
      ...prevCheckboxState,
      [name]: checked
    }));
    if (name === 'selectAll') {
      const updatedCheckboxState: CheckboxState = {};
      direccionOptions.forEach((option) => {
        updatedCheckboxState[option] = checked;
      });
      setDireccionCheckboxState(updatedCheckboxState);
    } else {
      const allCheckboxesChecked = direccionOptions.every(
        (option) => direccionCheckboxState[option]
      );
      setDireccionCheckboxState((prevCheckboxState) => ({
        ...prevCheckboxState,
        selectAll: allCheckboxesChecked
      }));
    }
  };

  const handleNegocioCheckboxClick = (name: string, checked: boolean) => {
    setNegocioCheckboxState((prevCheckboxState) => ({
      ...prevCheckboxState,
      [name]: checked
    }));
    if (name === 'selectAll') {
      const updatedCheckboxState: CheckboxState = {};
      negocioOptions.forEach((option) => {
        updatedCheckboxState[option] = checked;
      });
      setNegocioCheckboxState(updatedCheckboxState);
    } else {
      const allCheckboxesChecked = negocioOptions.every((option) => negocioCheckboxState[option]);
      setNegocioCheckboxState((prevCheckboxState) => ({
        ...prevCheckboxState,
        selectAll: allCheckboxesChecked
      }));
    }
  };

  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    // const startFormatted = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`;
    // const endFormatted = `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`;

    setStartDate(start);
    setSelectedDate(start);
    setEndDate(end);
  };

  const changeModalState = () => {
    isfiltersModal(false);
  };

  const sendEventoFilter = () => {
    isEventoFilter(filtro);
    changeModalState();
  };

  const cleanFilters = () => {
    setFiltro('');
    isEventoFilter(filtro);
    changeModalState();
  };

  const handleDate = (e: any) => {
    const inputDate = e.target.value;
    setSelectedDate(inputDate);
  };

  const getDictionary = (key: string) => {
    return filterOptions[key];
  };

  useEffect(() => {
    const eventoItems: any = Object.keys(eventoCheckboxState).filter(
      (key) => eventoCheckboxState[key]
    );
    const eventoFilter = eventoItems.map((item: any) => {
      return '&evento[]=' + item;
    });
    const actualizacionItems = Object.keys(actualizacionCheckboxState).filter(
      (key) => actualizacionCheckboxState[key]
    );
    const actualizacionFilter = actualizacionItems.map((item) => {
      return '&actualizado=' + item;
    });
    const estadoItems = Object.keys(estadoCheckboxState).filter((key) => estadoCheckboxState[key]);
    const estadoFilter = estadoItems.map((item) => {
      return '&estado[]=' + item;
    });
    const puestoItems = Object.keys(puestoCheckboxState).filter((key) => puestoCheckboxState[key]);
    const puestoFilter = puestoItems.map((item) => {
      return '&puesto[]=' + item;
    });
    const direccionItems = Object.keys(direccionCheckboxState).filter(
      (key) => direccionCheckboxState[key]
    );
    const direccionFilter = direccionItems.map((item) => {
      return '&direccion[]=' + item;
    });
    const negocioItems = Object.keys(negocioCheckboxState).filter(
      (key) => negocioCheckboxState[key]
    );
    const negocioFilter = negocioItems.map((item) => {
      return '&negocio[]=' + item;
    });
    const eventoToString = eventoFilter.toString();
    const actualizacionToString = actualizacionFilter.toString();
    const estadoToString = estadoFilter.toString();
    const puestoToString = puestoFilter.toString();
    const direccionToString = direccionFilter.toString();
    const negocioToString = negocioFilter.toString();
    const filterConcat =
      eventoToString +
      actualizacionToString +
      estadoToString +
      puestoToString +
      direccionToString +
      negocioToString;
    const filterCleaning = filterConcat.replace(/,/g, '');
    let filterCleaned =
      selectedDate === null
        ? '' + filterCleaning
        : '&fecha_contratacion_inicio=' + selectedDate + filterCleaning;
    filterCleaned += endDate ? `&fecha_contratacion_fin=${format(endDate, "yyyy-MM-dd")}` : ''
    setFiltro(filterCleaned);
  }, [
    selectedDate,
    eventoCheckboxState,
    actualizacionCheckboxState,
    estadoCheckboxState,
    puestoCheckboxState,
    direccionCheckboxState,
    negocioCheckboxState,
    filtro,
    endDate
  ]);

  return (
    <div className="colabs-filter-modal">
      <div className="colabs-filter-container">
        <div className="colabs-filter-header">
          <p>Filtros</p>
          <img src={Quit} alt="quit-logo" onClick={changeModalState} />
        </div>
        <div
          className="fecha-grupos"
          style={{
            marginTop: 16,
            marginLeft: 16
          }}
        >
          <div>
            <label
              htmlFor="picker"
              style={{
                color: 'black'
              }}
            >
              Fecha de Contratación
            </label>
            {/* <p className="label-title">Elige una semana</p>
            <p className="date-picker">Mes / Semana</p> */}
            <DatePicker
              id="picker"
              className="picker"
              selected={startDate}
              onChange={onChange}
              showYearDropdown
              dateFormatCalendar="MMMM"
              dateFormat="yyyy-MM-dd"
              yearDropdownItemNumber={50}
              scrollableYearDropdown
              selectsRange
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
        <div>
          <p className="colabs-filter-option">Evento</p>
          <div className="checkbox-container">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  name="selectAll"
                  checked={
                    eventoOptions.length > 0 &&
                    eventoOptions.every((option) => eventoCheckboxState[option])
                  }
                  onChange={(e) => handleEventoCheckboxClick('selectAll', e.target.checked)}
                />
                Agregar todos
              </label>
            </div>
            {eventoOptions.map((option) => (
              <div key={option} className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name={option}
                    checked={eventoCheckboxState[option] || false}
                    onChange={(e) => handleEventoCheckboxClick(option, e.target.checked)}
                  />
                  {getDictionary(option)}
                </label>
              </div>
            ))}
          </div>
          <div>
            <p className="colabs-filter-option">Estado de Actualizacion</p>
            <div className="checkbox-container">
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="selectAll"
                    checked={
                      actualizacionOptions.length > 0 &&
                      actualizacionOptions.every((option) => actualizacionCheckboxState[option])
                    }
                    onChange={(e) =>
                      handleActualizacionCheckboxClick('selectAll', e.target.checked)
                    }
                  />
                  Agregar Ambos
                </label>
              </div>
              {actualizacionOptions.map((option) => (
                <div key={option} className="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      name={option}
                      checked={actualizacionCheckboxState[option] || false}
                      onChange={(e) => handleActualizacionCheckboxClick(option, e.target.checked)}
                    />
                    {option === 'true' ? 'Actualizados' : 'Nuevos'}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div>
          <p className="colabs-filter-option">Estado del Colaborador</p>
          <div className="checkbox-container">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  name="selectAll"
                  checked={
                    estadoOptions.length > 0 &&
                    estadoOptions.every((option) => estadoCheckboxState[option])
                  }
                  onChange={(e) => handleEstadoCheckboxClick('selectAll', e.target.checked)}
                />
                Agregar Ambos
              </label>
            </div>
            {estadoOptions.map((option) => (
              <div key={option} className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name={option}
                    checked={estadoCheckboxState[option] || false}
                    onChange={(e) => handleEstadoCheckboxClick(option, e.target.checked)}
                  />
                  {getDictionary(option)}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div>
          <p className="colabs-filter-option">Puesto</p>
          <div className="checkbox-container">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  name="selectAll"
                  checked={
                    puestoOptions.length > 0 &&
                    puestoOptions.every((option) => puestoCheckboxState[option])
                  }
                  onChange={(e) => handlePuestoCheckboxClick('selectAll', e.target.checked)}
                />
                Agregar todos
              </label>
            </div>
            {puestoOptions.map((option) => (
              <div key={option} className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name={option}
                    checked={puestoCheckboxState[option] || false}
                    onChange={(e) => handlePuestoCheckboxClick(option, e.target.checked)}
                  />
                  {getDictionary(option)}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div>
          <p className="colabs-filter-option">Dirección</p>
          <div className="checkbox-container">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  name="selectAll"
                  checked={
                    direccionOptions.length > 0 &&
                    direccionOptions.every((option) => direccionCheckboxState[option])
                  }
                  onChange={(e) => handleDireccionCheckboxClick('selectAll', e.target.checked)}
                />
                Agregar todos
              </label>
            </div>
            {direccionOptions.map((option) => (
              <div key={option} className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name={option}
                    checked={direccionCheckboxState[option] || false}
                    onChange={(e) => handleDireccionCheckboxClick(option, e.target.checked)}
                  />
                  {getDictionary(option)}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div>
          <p className="colabs-filter-option">Negocio</p>
          <div className="checkbox-container">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  name="selectAll"
                  checked={
                    negocioOptions.length > 0 &&
                    negocioOptions.every((option) => negocioCheckboxState[option])
                  }
                  onChange={(e) => handleNegocioCheckboxClick('selectAll', e.target.checked)}
                />
                Agregar todos
              </label>
            </div>
            {negocioOptions.map((option) => (
              <div key={option} className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name={option}
                    checked={negocioCheckboxState[option] || false}
                    onChange={(e) => handleNegocioCheckboxClick(option, e.target.checked)}
                  />
                  {getDictionary(option)}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="upload-file-buttons">
          <button className="cancel-button" onClick={cleanFilters}>
            QUITAR FILTROS
          </button>
          <button className="accept-button" onClick={sendEventoFilter}>
            APLICAR FILTROS
          </button>
        </div>
      </div>
    </div>
  );
}

export default ColabsFilterModal;
