const initialState = {
    userData: {
      id: null,
      nomina: '',
      nombre: '',
      role: '',
      genero: '',
      fecha_nacimiento: '',
      foto: null,
      active: false,
      createdAt: '',
      updatedAt: '',
      direccion: "",
      ubicacion: "",
    },
    error: null,
  };
  
  const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_USER_SUCCESS':
        return {
          ...state,
          userData: {
            id: action.payload.user.id,
            nomina: action.payload.user.nomina,
            nombre: action.payload.user.nombre,
            role: action.payload.user.role,
            genero: action.payload.user.genero,
            fecha_nacimiento: action.payload.user.fecha_nacimiento,
            foto: action.payload.user.foto,
            active: action.payload.user.active,
            direccion: action.payload.user.direccion,
            ubicacion: action.payload.user.ubicacion,
            createdAt: action.payload.user.createdAt,
            updatedAt: action.payload.user.updatedAt,
          },
        };
      case 'GET_USER_FAILURE':
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default userReducer;  