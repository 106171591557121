export const eventoOptions = [
  'BAJA',
  'CAMBIO DE DATOS',
  'CAMBIO DE POSICION',
  'CAMBIO DE SUELDO',
  'INGRESO',
  'PROMOCION',
  'REACTIVACION DE EMPLEADO',
  'REINGRESO',
  'SUSPENSION'
];

export const actualizacionOptions = ['true', 'false'];

export const estadoOptions = ['ACTIVO', 'BAJA'];

export const gruposOptions = [
  'Administrador sucursal 1',
  'Asesor CI 1',
  'Cajero 1',
  'Cajero 2',
  'Cajero 3',
  'Gerente OS 1',
  'Subgerente grupal 1',
  'Subgerente grupal 2',
];

export const puestoOptions = [
  'ADMINISTRADOR OPERACIONES',
  'ADMINISTRADOR SUCURSAL',
  'ASESOR CI',
  'ASESOR COMERCIAL',
  'AUXILIAR OPERACIONES',
  'CAJERO',
  'CAJERO COMODIN',
  'EJECUTIVO',
  'GERENTE OS',
  'GESTOR',
  'LIDER',
  'PROMOTOR CREDITO GRUPAL',
  'SUBGERENTE CI',
  'SUBGERENTE GRUPAL'
];

export const direccionOptions = [
  'CENTRO',
  'CENTRO NORTE',
  'METRO',
  'NORESTE',
  'OCCIDENTE',
  'PACIFICO',
  'PENINSULA',
  'SUR',
  'SURESTE',
  'DIRECCION OPERACIONES',
  'DIRECCION VENTAS CENTRO',
  'DIRECCION VENTAS METRO',
  'DIRECCION VENTAS NORTE',
  'DIRECCION VENTAS NORTE PACIFICO',
  'DIRECCION VENTAS SUR'
];

export const negocioOptions = ['OPERACIONES', 'VENTAS', 'YASTAS'];
