import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Upload from '../../assets/upload-logo.png';
import LeftArrow from '../../assets/arrow-left.png';
import RightArrow from '../../assets/arrow-right.png';
import SuccessLogo from '../../assets/success-logo.png';
import Quit from '../../assets/quit.png';
import Build from '../../assets/build-logo.png';
import ubicaccion from '../../assets/ubicacion.png';
import Agenda from '../../assets/agenda.png';
import FormadorLogo from '../../assets/formador-logo.png';
import ErrorLogo from '../../assets/error-logo.png';
import { useNavigate } from 'react-router-dom';
import { getFormadoresLigados } from '../../services/getFormadoresLigados';
import { downloadFormadores } from '../../services/downloadFormadores ';
import { eliminarFormador } from '../../services/eliminarFormador';
// import { actualizarFormador } from '../../services/actualizarFormador ';
import UpdateFormadorModal from '../../components/liderFormador/updateFormadorModal';
import AgregarFormadorModal from '../../components/liderFormador/agregarFormadorModal';
import UserFallback from '../../assets/user-fallback-black.svg';
import Download from '../../assets/download-logo.png';
import ThreeDots from '../../assets/three-dots.svg';
import { AppState, Formador, PaginationInfo } from '../../types/types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

function Formadores() {
  const navigate = useNavigate();

  const [updateFormadoresResponse, setUpdateYastasResponse] = useState<boolean | null>(null);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo | null>(null);
  const [pagination, setPagination] = useState<number>(1);
  const [formadorActualizado, setFormadorActualizado] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const nombreUsuario = useSelector((state: AppState) => state.user.userData?.nombre);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedFormador, setSelectedFormador] = useState<Formador | null>(null);
  const [formadorEliminado, setFormadorEliminado] = useState<boolean>(false);
  const [formadorAgregado, setFormadorAgregado] = useState<boolean>(false);
  const [isAgregarFormadorModalOpen, setIsAgregarFormadorModalOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const closeAlert = () => {
    setUpdateYastasResponse(null);
  };

  const closeLoadingAlert = () => {
    setLoadingData(false);
  };

  const navigateToAgenda = (formadorId: any) => {
    navigate(`/formadores/agenda/${formadorId}`);
  };

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    const apiCall = () => {
      if (token) {
        getFormadoresLigados(token)
          .then((result) => {
            setFormadores(result.data.formadores);
            setPaginationInfo(result.data.pagination);
          })
          .catch((error) => {
            console.error('Error al obtener formadores', error);
          });
      }
    };

    apiCall();

    if (updateFormadoresResponse || formadorEliminado || formadorActualizado || formadorAgregado) {
      apiCall();
      setLoadingData(false);
      // Resetear el estado para evitar llamadas redundantes.
      if (formadorActualizado) setFormadorActualizado(false);
      if (formadorAgregado) setFormadorAgregado(false);
      setTimeout(() => {
        setUpdateYastasResponse(null);
      }, 5000);
    }
  }, [
    pagination,
    updateFormadoresResponse,
    formadorEliminado,
    formadorActualizado,
    formadorAgregado
  ]);

  const downloadFormador = () => {
    const token = sessionStorage.getItem('token');
    if (token) {
      downloadFormadores(token)
        .then((result) => {
          window.open(result.url);
        })
        .catch((error) => {
          console.error('Error al descargar archivo de formadores', error);
        });
    }
  };

  const handleClickOpen = (formador: any) => {
    setSelectedFormador(formador);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const openEditFormador = (formador: any) => {
    setSelectedFormador(formador);
    setIsModalOpen(true);
  };

  const openEliminarFormador = () => {
    const token = sessionStorage.getItem('token');
    if (token && selectedFormador && selectedFormador.nomina) {
      eliminarFormador(token, selectedFormador.nomina)
        .then(() => {
          console.log('Formador eliminado con éxito');
          setOpenDialog(false);
          setFormadorEliminado(!formadorEliminado);
        })
        .catch((error) => {
          console.error('Error al eliminar el formador', error);
        });
    } else {
      console.error('Token no disponible o ID de nómina del formador no proporcionado');
    }
  };

  const closeAgregarFormadorModal = () => setIsAgregarFormadorModalOpen(false);

  //paginacion

  const [formadores, setFormadores] = useState<Formador[]>([]);
  // Estado para paginación
  const [currentPage, setCurrentPage] = useState(1);
  const [formadoresPerPage, setFormadoresPerPage] = useState(12);

  // Calcula los índices de los formadores para la paginación
  const indexOfLastFormador = currentPage * formadoresPerPage;
  const indexOfFirstFormador = indexOfLastFormador - formadoresPerPage;
  const currentFormadores = formadores.slice(indexOfFirstFormador, indexOfLastFormador);

  // Manejadores para cambiar de página
  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    setCurrentPage(1); // Vuelve a la primera página cuando cambia la cantidad de formadores por página
  }, [formadoresPerPage]);

  //filtrar
  const totalFormadores = formadores.length;
  const totalPages = Math.ceil(totalFormadores / formadoresPerPage);
  const isLastPage = currentPage === totalPages;
  const formadoresLeftToShow = totalFormadores - (currentPage - 1) * formadoresPerPage;
  const shouldShowFillerItems = isLastPage && formadoresLeftToShow < formadoresPerPage;
  const fillerItemsNeeded = shouldShowFillerItems ? formadoresPerPage - formadoresLeftToShow : 0;

  // filtrar
  return (
    <div className="container colaboradores">
      <div className="flexRowSpaceBetween_Formadores">
        <div className="flexRowCenter">
          <img src={UserFallback} height={48} width={48} style={{ marginTop: '-10px' }} />
          <div className="flexColumnLeft">
            <p className="pBoldBlack" style={{ marginTop: '-10px' }}>
              {nombreUsuario || 'Cargando nombre...'}{' '}
            </p>
            <p className="pBoldBlue">Lider de formadores</p>
          </div>
        </div>
        <div className="flexRowCenter">
          <div className="flexRowGap14">
            <span className="spanBoldBlack">Dirección:</span>
            <p>Centro</p>
          </div>
        </div>
        <div className="flexRowGrey" onClick={() => setIsAgregarFormadorModalOpen(true)}>
          <div className="imgPointer">
            <img src={Upload} alt="upload-logo" />
            <p>AGREGAR FORMADOR</p>
          </div>
        </div>
        <div className="flexRowGrey imgPointer" onClick={downloadFormador}>
          <img src={Download} alt="download-logo" />
          <p>DESCARGAR</p>
        </div>
        {isModalOpen && (
          <UpdateFormadorModal
            isOpen={isModalOpen}
            setFormadorActualizado={setFormadorActualizado}
            onClose={() => setIsModalOpen(false)}
            formador={selectedFormador}
            updateFormador={function (subdireccion: string): void {
              throw new Error('Function not implemented.');
            }}
          />
        )}
        {isAgregarFormadorModalOpen && (
          <AgregarFormadorModal
            isOpen={isAgregarFormadorModalOpen}
            onClose={closeAgregarFormadorModal}
            onFormadorAgregado={() => setFormadorAgregado(true)}
          />
        )}
      </div>
      {loadingData === true && (
        <div className="update-file-response-container">
          <div className="update-file-message loading-data">
            <img src={SuccessLogo} alt="success-logo" />
            <p>Cargando Datos...</p>
            <img src={Quit} alt="quit-logo" onClick={closeLoadingAlert} className="close-alert" />
          </div>
        </div>
      )}
      {updateFormadoresResponse === true && (
        <div className="update-file-response-container">
          <div className="update-file-message update-file-success">
            <img src={SuccessLogo} alt="success-logo" />
            <p>La base de datos se actualizó satisfactoriamente</p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}
      {updateFormadoresResponse === false && (
        <div className="update-file-response-container">
          <div className="update-file-message update-file-error">
            <img src={ErrorLogo} alt="error-logo" />
            <p>
              Hubo un problema con el sistema y la base de datos no se actualizó, por favor,
              inténtalo de nuevo
            </p>
            <img src={Quit} alt="quit-logo" onClick={closeAlert} className="close-alert" />
          </div>
        </div>
      )}
      <div className="grid-container scrollable-container">
        {currentFormadores.map((formador) => (
          <div key={formador.formador_id} className="card">
            <div className="absolute-right">
              <div className="sede-selector-container td">
                <div className="dropdown">
                  <span>
                    <img src={ThreeDots} alt="menu" className="three-dots" />
                  </span>
                  <div className="dropdown-content">
                    <div onClick={() => openEditFormador(formador)}>
                      <img src={Build} alt="build-logo" className="build-logo" />
                      <div className="edit-button-container">
                        <p style={{ fontWeight: 500 }}>Editar Formador</p>
                      </div>
                    </div>

                    <div onClick={() => handleClickOpen(formador)}>
                      <img src={FormadorLogo} alt="formador-logo" />
                      <p style={{ fontWeight: 500 }}>Eliminar Formador</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flexRowCenter">
              <img src={UserFallback} height={48} width={48} />
              <div className="flexColumnLeft">
                <p className="pBoldBlack">{formador.nombre}</p>
              </div>
            </div>
            <div className="flex-row-space-between-gap">
              <img
                src={ubicaccion}
                alt="Ubicación"
                height={'40%'}
                width={'3.2%'}
                style={{ marginRight: '39px', marginTop: '4px' }}
              />
              <span style={{ position: 'absolute', marginLeft: '20px' }}>{formador.direccion}</span>
              <button
                className="card-button"
                onClick={() => navigateToAgenda(formador.formador_id)}
              >
                <img
                  src={Agenda}
                  alt="Agenda"
                  height={12}
                  width={12}
                  style={{ marginRight: '8px' }}
                />
                AGENDA
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="pagination-container">
        <div className="row-numbers">
          <p>Filas por página:</p>
          <select
            value={formadoresPerPage}
            onChange={(e) => setFormadoresPerPage(Number(e.target.value))}
          >
            <option value={9}>9</option>
            <option value={12}>12</option>
            <option value={15}>15</option>
            <option value={18}>18</option>
          </select>
        </div>
        <div className="pagination">
          <p>
            {indexOfFirstFormador + 1} - {Math.min(indexOfLastFormador, formadores.length)} de {formadores.length}
          </p>
          <div className="pagination-arrow">
            <img src={LeftArrow} alt="left-arrow" onClick={previousPage} />
            <img src={RightArrow} alt="right-arrow" onClick={nextPage} />
          </div>
        </div>
      </div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '550px',
              borderRadius: '20px'
            }
          },
          '& .MuiDialogTitle-root': {
            backgroundColor: 'white',
            color: 'black',
            textAlign: 'left',
            padding: '20px 24px',
            fontWeight: 'bold'
          },
          '& .MuiDialogContent-root': {
            paddingTop: '5px',
            paddingBottom: '20px',
            color: 'rgba(0, 0, 0, 0.6)'
          },
          '& .MuiDialogActions-root': {
            padding: '8px',

            justifyContent: 'center',
            borderTop: '1px solid #e0e0e0', // Aquí se agrega la línea gris
            '& .MuiButton-root': {
              m: '5px',
              color: '#652D89',
              boxShadow: 'none',
              fontWeight: 'bold',
              width: 'calc(50% - 10px)', // Restamos los márgenes para obtener un ancho igual
              height: '38px' // Fijamos una altura común
            },
            '& .MuiButton-outlined': {
              backgroundColor: 'white',
              flexGrow: 1,
              border: '1px solid #652D89', // Aseguramos que la altura total sea igual
              padding: '9px 16px', // Fijamos el padding para igualar la altura del texto
              '&:hover': {
                backgroundColor: '#652D89',
                color: 'white'
              }
            },
            '& .MuiButton-contained': {
              backgroundColor: 'white',
              flexGrow: 1,
              border: '1px solid #652D89',
              '&:hover': {
                backgroundColor: '#652D89',
                color: 'white'
              },
              padding: '9px 16px' // Fijamos el padding para igualar la altura del texto
            }
          }
        }}
      >
        <DialogTitle id="alert-dialog-title">{'Eliminar formador'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Estás a punto de eliminar a María del Rocio ZL como formador. ¿Estás segura de hacerlo?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancelar
          </Button>
          <Button onClick={openEliminarFormador} variant="contained" color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Formadores;
