import axios, { AxiosResponse, AxiosError } from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const authUser = async (nomina: string, password: string): Promise<string | null> => {
  try {
    const response: AxiosResponse = await axios.post(`${apiUrl}/api/auth/login`, {
      nomina,
      password
    });

    if (response.data && response.data.accessToken) {
      const token = response.data.accessToken;
      return token;
    } else {
      return null;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        console.error('Error de la API:', axiosError.response.status, axiosError.response.data);
      } else {
        console.error('Error de red:', axiosError.message);
      }
    }
    throw error;
  }
};
